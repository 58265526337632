<template>
    <div v-if="param.isShow" class="popup" style="display: block;">
        <div class="popup_header">
            <div class="header_title">{{ param.title }}</div>
            <button class="close_popup" @click="close()">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="popup_content">
            <div class="text_info_popup">
                {{ param.msg }}
            </div>
        </div>
        <div class="popup_footer">
            <div class="foot_btns">
                <button type="button" class="cancel" @click="close()">취소</button>
                <button type="button" class="comfirm" @click="ok(param.callback)">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Confirm',
    components: {},
    props: {
        param: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data: () => {
        return {};
    },
    computed: {},
    watch: {
        param: {
            immediate: true,
            deep: true,
            handler(val) {
                this.param = val;
            }
        }
    },

    beforeCreate() {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        close() {
            this.param.isShow = false;
        },
        ok() {
            this.param.isShow = false;
            this.$emit('confirmCallback', this.param.callbackParam);
        }
    }
};
</script>
