<template>
    <div :style="{ display: isShow ? 'block' : 'none' }" class="popup modify_site">
        <div class="popup_header">
            <div class="header_title">근무 형태</div>
            <button @click="close" class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="popup_content">
            <div class="form_wrap">
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">작업자</p>
                        <p class="title">{{ detailItem.name }}</p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">근무일</p>
                        <p class="title">{{ detailItem.day }}</p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write half">
                        <p class="title">출근시간 / 퇴근시간</p>
                        <p class="input half">
                            <input v-model="detailItem.startFormat" type="time" step="1" />
                        </p>
                        <p class="input half">
                            <input v-model="detailItem.endFormat" type="time" step="1" />
                        </p>
                    </div>
                </div>

                <div class="form_item">
                    <div class="form_write">
                        <p class="title">근무 형태</p>
                        <p class="input">
                            <label class="custom_radio color_label color_blue">
                                <input
                                    v-model="detailItem.workTimeType"
                                    type="radio"
                                    name="modi_color_01"
                                    value="NORMAL"
                                />
                                <p class="title">일반근무</p>
                            </label>
                            <label class="custom_radio color_label color_yellow">
                                <input
                                    v-model="detailItem.workTimeType"
                                    type="radio"
                                    name="modi_color_01"
                                    value="HALF"
                                />
                                <p class="title">반일근무</p>
                            </label>
                            <label class="custom_radio color_label color_purple">
                                <input
                                    v-model="detailItem.workTimeType"
                                    type="radio"
                                    name="modi_color_01"
                                    value="ADD"
                                />
                                <p class="title">추가근무</p>
                            </label>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup_footer">
            <div class="foot_btns">
                <button type="button" class="cancel" @click="close">취소</button>
                <button type="button" class="comfirm" @click="save">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatCommute } from '../../utils/date';
import CommuteApi from '../../api/coco/CommuteApi';

export default {
    name: 'PopupModifyCommute',
    components: {},
    props: {
        isShow: {
            type: Boolean
        },
        detailItem: {
            type: Object,
            default: null
        }
    },
    data: () => {
        return {};
    },
    computed: { ...mapGetters(['getLang']) },
    watch: {
        detailItem: function() {
            this.detailItem.startFormat = formatCommute(this.detailItem.startedAt);
            this.detailItem.endFormat = formatCommute(this.detailItem.endedAt);
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        close() {
            this.$emit('detailCallback');
        },
        save() {
            if (this.detailItem.startedAt) {
                this.detailItem.startedAt = this.detailItem.startedAt.slice(0, 11) + this.detailItem.startFormat;
            }
            if (this.detailItem.endedAt) {
                this.detailItem.endedAt = this.detailItem.endedAt.slice(0, 11) + this.detailItem.endFormat;
            }

            const param = {
                workTimeType: this.detailItem.workTimeType,
                startedAt: this.detailItem.startedAt,
                endedAt: this.detailItem.endedAt
            };

            CommuteApi.editWorkType(param, this.detailItem.id).then(() => {
                CommuteApi.modify(this.detailItem).then(() => {
                    this.$toast.success('저장 되었습니다.');
                    this.$emit('detailCallback');
                });
            });
        }
    }
};
</script>
