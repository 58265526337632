import 'core-js/es6/promise';
import '@babel/polyfill';
import { sync } from 'vuex-router-sync';
import Vue from 'vue';

import App from './App';
import store from './store';
import router from './router';

// TODO: get User's language from config

///////////////////////
// Vue Plugin List
///////////////////////
import Mapbox from 'mapbox-gl';
Vue.use(Mapbox);
import Vue2Editor from 'vue2-editor';
Vue.use(Vue2Editor);
///////////////////////
// Initialize Vue Root App Component
///////////////////////

import VueNumberFilter from './plugin/filter/vue-number-filter';
import VueImageFilter from './plugin/filter/vue-image-filter';
import VueStringFilter from './plugin/filter/vue-string-filter';
import VueDateFilter from './plugin/filter/vue-datetime-filter';

Vue.use(VueNumberFilter);
Vue.use(VueImageFilter);
Vue.use(VueStringFilter);
Vue.use(VueDateFilter);

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast, {
    position: 'bottom'
});

sync(store, router);

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');
