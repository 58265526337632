<template>
    <div class="popup logout_popup">
        <div class="popup_header">
            <div class="header_title">로그아웃</div>
            <button class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="popup_content">
            <div class="text_info_popup">
                로그아웃하시겠습니까?
            </div>
        </div>
        <div class="popup_footer">
            <div class="foot_btns">
                <button type="button" class="cancel">취소</button>
                <button type="button" class="comfirm">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PopupLogout',
    components: {},
    data: () => {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {}
};
</script>
