<template>
    <div class="popup inspection_popup">
        <div class="popup_header">
            <div class="header_title blue">8월 품질검사 관리대장 점검</div>
            <button class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="popup_content">
            <div class="inspac_content">
                <div class="tit_cont_wrap">
                    <p class="title">일정 기간</p>
                    <p class="text_cont">2022.08.04 ~ 2022.08.05</p>
                </div>
                <div class="inspac_word">
                    일정 주요 내용 표시 및 반영
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PopupInspection',
    components: {},
    data: () => {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {}
};
</script>
