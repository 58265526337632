<template>
    <vue-resizable :dragSelector="dragSelector" :style="{ display: workViewIsShow ? 'block' : 'none' }">
        <div
            :style="{ display: workViewIsShow ? 'block' : 'none' }"
            :class="
                workActionItem === null || workActionItem.length === 0 ? 'popup choco_popup' : 'popup rechoco_popup'
            "
            style="display:block;"
        >
            <!-- style="display:block;" -->
            <div class="popup_header">
                <div class="header_title">
                    실시간 작업지시 상황
                </div>
                <button class="close_popup">
                    <img src="@static/img/common/popup_close.svg" @click="close" />
                </button>
            </div>
            <div class="popup_content">
                <div class="choco_content">
                    <div class="tit_cont_wrap">
                        <p class="title">
                            요청자
                        </p>
                        <p class="text_cont">
                            {{ param.member ? param.member.name : '' }}
                        </p>
                    </div>
                    <div class="tit_cont_wrap">
                        <p class="title">
                            작성 일시
                        </p>
                        <p class="text_cont">
                            {{ time(param.createAt, 0) }}
                            {{ time(param.createAt, 1) }}
                        </p>
                    </div>
                    <div class="tit_cont_wrap">
                        <p class="title">
                            대상 업체
                        </p>
                        <p class="text_cont">
                            {{ param.targetCompany }}
                        </p>
                    </div>

                    <div class="job_data">
                        <div :class="!param.imageFileId ? 'hide' : ''" class="job_photo">
                            <!-- 사진 없이 텍스트만 있을 땐 hide 붙여요!! -->
                            <div class="swiper">
                                <!-- Additional required wrapper -->
                                <div class="swiper-wrapper">
                                    <!-- Slides -->
                                    <!-- //TODO img -->
                                    <div v-for="(img, index) in imgFileList" :key="index" class="swiper-slide">
                                        <img :src="img.imageUrl" alt="" />
                                    </div>
                                </div>
                                <!-- If we need pagination -->
                                <div class="swiper-pagination" />

                                <!-- If we need navigation buttons -->
                                <div v-show="imgFileList.length > 1" class="swiper-button-prev" />
                                <div v-show="imgFileList.length > 1" class="swiper-button-next" />

                                <!-- If we need scrollbar -->
                                <div class="swiper-scrollbar" />
                            </div>
                        </div>

                        <div class="job_text">
                            <pre>
                            {{ param.content }}
                            </pre>
                        </div>
                    </div>
                </div>

                <div class="rechoco_content">
                    <div class="item" v-for="(item, idx) in workActionItem" :key="idx">
                        <div class="tit_cont_wrap">
                            <p class="title">담당자</p>
                            <p class="text_cont">{{ item.member.name }}</p>
                        </div>
                        <div class="tit_cont_wrap">
                            <p class="title">작성 일시</p>
                            <p class="text_cont">
                                {{ time(item.updatedAt, 0) }}
                                {{ time(item.updatedAt, 1) }}
                            </p>
                        </div>
                        <div class="tit_cont_wrap">
                            <p class="title">소속 업체</p>
                            <p class="text_cont">{{ item.member.company.name }}</p>
                        </div>
                        <div class="job_data">
                            <div :class="!item.imageFileId ? 'hide' : ''" class="job_photo">
                                <!-- 사진 없이 텍스트만 있을 땐 hide 붙여요!! -->

                                <div class="swiper">
                                    <!-- Additional required wrapper -->
                                    <div class="swiper-wrapper">
                                        <!-- Slides -->
                                        <!-- //TODO img -->
                                        <div
                                            v-for="(img, index) in imgActionList.filter(
                                                img => img.id === item.imageFileId
                                            )"
                                            :key="index"
                                            class="swiper-slide"
                                        >
                                            <img :src="img.list.imageUrl" alt="" />
                                        </div>
                                    </div>
                                    <!-- If we need pagination -->
                                    <div class="swiper-pagination" />

                                    <!-- If we need navigation buttons -->
                                    <div
                                        v-show="imgActionList.filter(img => img.id === item.imageFileId).length > 1"
                                        class="swiper-button-prev"
                                    />
                                    <div
                                        v-show="imgActionList.filter(img => img.id === item.imageFileId).length > 1"
                                        class="swiper-button-next"
                                    />

                                    <!-- If we need scrollbar -->
                                    <div class="swiper-scrollbar" />
                                </div>
                            </div>

                            <div class="job_text">
                                <pre>
                                {{ item.content }}
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- right //-->
            </div>
        </div>
    </vue-resizable>
</template>
<script>
import VueResizable from 'vue-resizable';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/vue/swiper';
import FileApi from '../../api/coco/FileApi';
import { bindFilesBlobImageThumb } from '../../helper/ImageHelper';

Swiper.use([Navigation, Pagination]);

export default {
    name: 'PopupWorkView',
    components: { VueResizable },

    props: {
        workViewIsShow: {
            type: Boolean
        },
        workViewItem: {
            type: Object,
            default: null
        },
        workActionItem: {
            type: Array,
            default: null
        }
    },
    data: () => {
        return {
            param: {},
            imageFileId: null,
            imageFileIdAction: [],
            imgFileListWorkAction: [],
            imgFileList: [],
            imgActionList: [],

            dragSelector: '.popup_header, .popup rechoco_popup, .popup_content',
            handlers: ['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt']
        };
    },
    computed: {},
    watch: {
        workActionItem: function() {
            if (this.workActionItem) {
                this.getImageList();
            }
        },
        workViewItem: function() {
            this.param = JSON.parse(JSON.stringify(this.workViewItem));

            if (this.imageFileId !== this.param.imageFileId) {
                this.imageFileId = this.param.imageFileId;

                this.bindWorkImage();
            }
        }
    },
    created() {},
    mounted() {
        new Swiper('.swiper', {
            // loop: true,
            pagination: {
                el: '.swiper-pagination'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            scrollbar: {
                el: '.swiper-scrollbar'
            }
        });
    },
    beforeDestroy() {},
    methods: {
        close() {
            this.imageFileIdAction = [];
            this.imgFileListWorkAction = [];
            this.$emit('closeWorkView');
        },
        async bindWorkImage() {
            if (this.imageFileId) {
                this.imgFileList = await FileApi.list(this.imageFileId);
                await bindFilesBlobImageThumb(this.imgFileList, '800X800');
            }
        },

        async getImageList() {
            this.imgActionList = [];
            if (this.workActionItem !== null) {
                for (const value of this.workActionItem) {
                    if (value.imageFileId !== null) {
                        const temp = await FileApi.list(value.imageFileId);
                        await bindFilesBlobImageThumb(temp, '800X800');
                        temp.forEach(element => {
                            this.imgActionList.push({ id: value.imageFileId, list: element });
                        });
                    }
                }
            }
        },
        time(item, id) {
            if (item) {
                const result = item.split('T');
                return result[id];
            } else {
                return '';
            }
        }
    },
    updated() {
        new Swiper('.swiper', {
            // loop: true,
            pagination: {
                el: '.swiper-pagination'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            scrollbar: {
                el: '.swiper-scrollbar'
            }
        });
    }
};
</script>

<style scoped>
.resizable-component {
    /* top: 50% !important;
    left: 50% !important; */
    /* transform: translate(-50%, -50%); */
    position: fixed;
    z-index: 2;
}
</style>
