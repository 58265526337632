<template>
    <div :style="{ display: tabId === 1 ? 'block' : 'none' }" class="contain_wrap_tab_01">
        <div v-for="(item, index) in filterList" :key="index" class="name_card" data-rquip="roller">
            <div :class="getMemberStatus(item)" class="name_card_vi">{{ item.name.charAt(0) }}</div>
            <div class="name_card_info">
                <p class="name_card_name">{{ DRIVER_TYPE[item.driverType] }} / {{ item.name | mskName }}</p>
                <p class="name_card_comp">{{ item.carNo }}</p>
                <p class="name_card_time">{{ item.uuid }}</p>
            </div>
            <div class="name_card_checkin">
                <p :class="item.checkInTime ? 'on' : ''" class="check_in">
                    <span class="badge">출근</span> {{ item.checkInTime ? item.checkInTime : '-' }}
                </p>
                <p :class="item.checkOutTime ? 'on' : ''" class="check_out">
                    <span class="badge">퇴근</span>{{ item.checkOutTime ? item.checkOutTime : '-' }}
                </p>
                <p v-show="item.battery" class="battery">
                    <span :class="getBatteryCls(item.battery)" class="charge" />{{ item.battery }}%
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CommuteApi from '../../../../api/coco/CommuteApi';
import BleDeviceApi from '../../../../api/osm/BleDeviceApi';
import { DRIVER_TYPE } from '../../../../const';
import { getMemberStatus, getBatteryCls } from '../../../../utils/objectUtil';
export default {
    name: 'EquipmentContent',
    components: {},
    props: {
        tabId: {
            type: Number,
            default: 1
        },
        searchText: {
            type: String,
            default: ''
        },
        isBounds: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            memberList: [],
            filterList: [],
            DRIVER_TYPE: {},
            hereSearchText: ''
        };
    },
    computed: {
        ...mapState({
            carList: state => state.common.carList,
            mapBounds: state => state.common.mapBounds
        })
    },
    watch: {
        searchText: function() {
            this.filterFn();
        },
        carList: async function() {
            const ids = this.carList.map(item => item.id);
            let commuteMap, bleMap;
            if (ids.length > 0) {
                const commuteResult = await CommuteApi.detailIds({ ids: ids });
                commuteMap = new Map(
                    commuteResult.content.map(object => {
                        return [object.memberId, object];
                    })
                );

                const bleResult = await BleDeviceApi.bleDetailIds({ ids: ids });
                bleMap = new Map(
                    bleResult.content
                        .filter(object => object !== null)
                        .map(object => {
                            if (object) return [object.memberId, object];
                        })
                );
            }

            this.carList.map((item, index) => {
                if (commuteMap.has(item.id)) {
                    const memberDetail = commuteMap.get(item.id);
                    var bleDetail = bleMap.get(item.id);

                    this.memberList[index] = Object.assign(
                        {
                            checkInTime: memberDetail.startedAt,
                            checkOutTime: memberDetail.endedAt,
                            totalHour: memberDetail.totalWorkTime,
                            uuid: bleDetail ? bleDetail.uuid : null,
                            battery: bleDetail ? bleDetail.battery : null
                        },
                        item
                    );
                    this.memberList[index].checkInTime = this.shortTime(memberDetail.startedAt);
                    this.memberList[index].checkOutTime = this.shortTime(memberDetail.endedAt);
                    this.memberList[index].totalHour = memberDetail.totalWorkTime;
                } else {
                    this.memberList[index] = Object.assign(
                        {
                            checkInTime: null,
                            checkOutTime: null,
                            totalHour: 0,
                            uuid: null,
                            battery: null
                        },
                        item
                    );
                }
            });
            this.setCarList(this.memberList);
            this.filterFn();
        },
        mapBounds: function() {
            this.setFilterBound();
        },
        isBounds: function() {
            if (this.isBounds) {
                this.setFilterBound();
            } else {
                this.filterList = this.memberList;
            }
        }
    },
    created() {},
    mounted() {
        this.DRIVER_TYPE = DRIVER_TYPE;
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setCarList']),
        getMemberStatus: getMemberStatus,
        getBatteryCls: getBatteryCls,
        setFilterBound() {
            if (this.isBounds) {
                if (this.mapBounds._ne) {
                    this.filterList = this.memberList;
                    this.filterList = this.filterList.filter(item => {
                        return (
                            item.lng <= this.mapBounds._ne.lng &&
                            item.lat <= this.mapBounds._ne.lat &&
                            item.lng >= this.mapBounds._sw.lng &&
                            item.lat >= this.mapBounds._sw.lat
                        );
                    });
                }
            }
        },
        shortTime(dateStr) {
            if (dateStr) {
                if (dateStr.length > 18) {
                    return dateStr.substr(11, 8);
                }
            }
            return dateStr;
        },
        filterFn() {
            if (this.searchText.length >= 1) {
                this.filterList = this.memberList.filter(
                    item =>
                        item.name.indexOf(this.searchText) > -1 ||
                        item.company.indexOf(this.searchText) > -1 ||
                        item.carNo.indexOf(this.searchText) > -1
                );
            } else {
                if (this.memberList.length !== this.filterList) {
                    this.filterList = this.memberList;
                }
            }
        }
    }
};
</script>
