import {
    getDayOfWeek,
    formatTime,
    longFormatTime,
    shortTime,
    formatCommute,
    formatShortCommute
} from '../../utils/date';

const VueDateTimeFilter = {
    install(Vue) {
        Vue.filter('formatTime', formatTime);
        Vue.filter('longFormatTime', longFormatTime);
        Vue.filter('shortTime', shortTime);
        Vue.filter('formatCommute', formatCommute);
        Vue.filter('getDayOfWeek', getDayOfWeek);
        Vue.filter('formatShortCommute', formatShortCommute);
    }
};

export default VueDateTimeFilter;
