<template>
    <div :style="{ display: tabId === 6 ? 'block' : 'none' }" class="dash_admin_box dash_commute_01">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">
                비정상 탑승로그
            </p>
        </div>

        <!-- 검색 Wrap -->
        <div class="option_wrap">
            <div class="search_filter">
                <form>
                    <div class="option_date">
                        <date-picker
                            id="startedDate"
                            v-model="listParam.beginDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                        <span>~</span>
                        <date-picker
                            id="endedDate"
                            v-model="listParam.endDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                    </div>
                    <div class="search_button">
                        <button type="button" @click="onClickSearch">
                            검색
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <!-- 검색 Wrap //-->

        <div class="dash_admin_box_table no-page-log">
            <table>
                <thead>
                    <tr>
                        <th>
                            순번
                        </th>
                        <th>
                            업체명
                        </th>
                        <th>
                            장비기사
                        </th>
                        <th>
                            차량종류
                        </th>
                        <th>
                            등록 차량번호
                        </th>
                        <th>
                            탑승 차량번호
                        </th>
                        <th>
                            등록일시
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="content.length === 0">
                        <td colspan="7">
                            검색 결과가 없습니다.
                        </td>
                    </tr>
                    <template v-if="content.length !== 0">
                        <tr v-for="(item, index) in content" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.companyName }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.driverType | getDriverType }}</td>
                            <td>{{ item.orgCarNo }}</td>
                            <td>{{ item.carNo }}</td>
                            <td>{{ item.formatCreatedAt }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import InfraApi from '../../../api/coco/InfraApi';
import BleDeviceApi from '../../../api/osm/BleDeviceApi';
import MemberApi from '../../../api/coco/MemberApi';
import { getToday, toDayFormatAddDay } from '../../../utils/date';
import { mapGetters } from 'vuex';
export default {
    name: 'DashCommute01',
    components: { DatePicker },
    props: {
        tabId: {
            type: Number,
            default: 1
        }
    },
    data: () => {
        return {
            companyList: [],
            memberList: [],
            pageParam: { content: [] },
            listParam: {
                beginDate: toDayFormatAddDay(-30),
                endDate: getToday()
            },
            content: [],
            isShow: false,
            detailItem: null,
            signFileId: {}
        };
    },
    computed: { ...mapGetters(['getLang']) },
    watch: {
        tabId: function() {
            if (this.tabId === 1) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {
        InfraApi.companyList().then(res => {
            this.companyList = res;
        });
        MemberApi.driverList().then(res => {
            this.memberList = res.content;
            this.list();
        });
    },
    beforeDestroy() {},
    methods: {
        onClickSearch() {
            this.listParam.page = 1;
            this.list();
        },
        async list() {
            const result = await BleDeviceApi.wrongList(this.listParam);

            const filterResult = result
                .map(item => {
                    const member = this.memberList.find(member => member.id === item.memberId);
                    if (member) {
                        item.name = member.name;
                        item.orgCarNo = member.carNo;
                        item.companyName = member.companyName;
                        item.driverType = member.driverType;
                        item.formatCreatedAt = this.postgresqlFormat(item.createdAt);
                        if (item.carNo) {
                            if (item.carNo !== item.orgCarNo) return item;
                        }
                    }
                })
                .filter(item => item !== undefined);
            this.content = filterResult;
        },
        pageCallback(page) {
            this.listParam.page = page;
            this.list();
        },
        postgresqlFormat(datetime) {
            const result = datetime.split('T');
            return result[0] + ' ' + result[1].slice(0, 8);
        }
    }
};
</script>
