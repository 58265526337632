<template>
    <div :style="{ display: tabId === 2 ? 'block' : 'none' }" class="dash_admin_box dash_commute_02">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">
                작업자 출근대장
            </p>
        </div>
        <!-- 검색 Wrap -->
        <div class="option_wrap">
            <div class="search_filter">
                <form>
                    <div class="option_date">
                        <date-picker
                            id="startedDate"
                            v-model="listParam.beginDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                        <span>~</span>
                        <date-picker
                            id="endedDate"
                            v-model="listParam.endDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                    </div>
                    <select v-show="authType === 'ADMIN'" v-model="listParam.companyId" class="search_option">
                        <option value="">
                            선택
                        </option>
                        <option v-for="(code, index) in companyList" :key="index" :value="code.id">
                            {{ code.name }}
                        </option>
                    </select>

                    <div class="option_date w100">
                        <input
                            v-model="listParam.name"
                            type="text"
                            placeholder="성명 검색"
                            @keyup.enter="onClickSearch"
                        />
                    </div>

                    <div class="search_button">
                        <button type="button" @click="onClickSearch">
                            검색
                        </button>
                    </div>
                    <div class="search_button">
                        <button type="button" @click="confirm(memberIds)">
                            일지확정
                        </button>
                    </div>
                    <div class="search_button">
                        <button type="button" @click="cancel(memberIds)">
                            확정취소
                        </button>
                    </div>
                </form>
            </div>
            <div class="right_area">
                <div class="download_button">
                    <button type="button" @click="excel">
                        엑셀 다운로드
                    </button>
                </div>
            </div>
        </div>
        <!-- 검색 Wrap //-->

        <div class="dash_admin_box_table">
            <table>
                <thead>
                    <tr>
                        <th width="40">
                            <label class="custom_checkbox">
                                <input v-model="allSelected" type="checkbox" @click="selectAll" />
                            </label>
                        </th>
                        <th width="90">
                            순번
                        </th>
                        <th>
                            근무일
                        </th>
                        <th>
                            업체명
                        </th>
                        <th>
                            성명
                        </th>
                        <th>
                            출근시간
                        </th>
                        <th>
                            퇴근시간
                        </th>
                        <th>
                            비고
                        </th>
                        <th>
                            근무형태
                        </th>
                        <th>
                            확정일자
                        </th>
                        <th>
                            담당자
                        </th>
                        <th>
                            서명
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="pageParam.total === 0">
                        <td colspan="12">
                            검색 결과가 없습니다.
                        </td>
                    </tr>
                    <template v-if="pageParam.total !== 0">
                        <tr v-for="(item, index) in pageParam.content" :key="index">
                            <td>
                                <label class="custom_checkbox">
                                    <input v-model="memberIds" :value="item.id" type="checkbox" @click="select" />
                                </label>
                            </td>
                            <td>{{ pageParam.total - ((listParam.page - 1) * listParam.size + index) }}</td>
                            <td>{{ item.day }}</td>
                            <td>{{ item.companyName }}</td>
                            <td>{{ item.name }}</td>
                            <td><img :src="imgFaults(item.startedFault)" /> {{ item.startedAt | formatCommute }}</td>
                            <td>
                                <img v-if="item.endedAt" :src="imgFaults(item.endedFault)" />
                                {{ item.endedAt | formatCommute }}
                            </td>
                            <td>{{ contentSubStr(item.remark) }}</td>
                            <td @click="openPopup(item)">{{ item.workTimeType | getWorkType }}</td>
                            <td>{{ item.confirmedAt }}</td>
                            <td>{{ item.confirmedName }}</td>
                            <td class="sign_thumb">
                                <img v-if="item.imageUrl" :src="item.imageUrl" />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <pagination :page-param="pageParam" :list-cnt="listParam.size" @pageCallback="pageCallback" />
        <con-firm :param="confirmParam" @confirmCallback="confirmCallback" />
        <popup-modify-commute :detail-item="detailItem" :is-show="detailIsShow" @detailCallback="detailCallback" />
    </div>
</template>
<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import Pagination from '../../layout/Pagination.vue';
import CommuteApi from '../../../api/coco/CommuteApi';
import InfraApi from '../../../api/coco/InfraApi';
import ImageApi from '../../../api/coco/ImageApi';
import { getToday, toDayFormatAddDay, formatDate } from '../../../utils/date';
import { AUTH_TYPE } from '../../../const';
import { mapGetters } from 'vuex';
import ConFirm from '../Confirm.vue';
import PopupModifyCommute from '../PopupModifyCommute.vue';
export default {
    name: 'DashCommute02',
    components: { Pagination, DatePicker, ConFirm, PopupModifyCommute },
    props: {
        tabId: {
            type: Number,
            default: 2
        }
    },
    data: () => {
        return {
            confirmParam: {
                title: '',
                msg: '',
                isShow: false,
                confirm: false
            },
            companyList: [],
            pageParam: { content: [] },
            listParam: {
                page: 1,
                size: 10,
                isDriver: false,
                beginDate: toDayFormatAddDay(-7),
                endDate: getToday(),
                companyId: '',
                name: ''
            },
            isShow: false,
            signFileId: {},
            authType: localStorage.getItem(AUTH_TYPE),
            allSelected: false,
            selected: [],
            memberIds: [],
            detailItem: null,
            detailIsShow: false
        };
    },
    computed: { ...mapGetters(['getLang']) },
    watch: {
        tabId: function() {
            if (this.tabId === 2) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {
        //this.list();
        InfraApi.companyList().then(res => {
            this.companyList = res;
        });
    },
    beforeDestroy() {},
    methods: {
        onClickSearch() {
            this.listParam.page = 1;
            this.list();
        },
        async list() {
            const response = await CommuteApi.bookList(this.listParam);

            const signFileIds = [];

            for (var item of response.content) {
                if (item.signFileId && !this.signFileId[item.signFileId]) {
                    item.imageUrl = '';
                    signFileIds.push({ fileId: item.signFileId, fileNo: 2 });
                } else {
                    item.imageUrl = this.signFileId[item.signFileId];
                }
                item.isEdit = false;
                item.confirmedAt = formatDate(item.confirmedAt);
            }

            //한번에 가져와서 바인딩 하기
            if (signFileIds.length > 0) {
                try {
                    const signResponse = await ImageApi.getBlobList({ ids: signFileIds });
                    signResponse.map(item => {
                        try {
                            const url = 'data:image/png;base64,' + item.blob;
                            const targetRes = response.content.filter(data => data.signFileId === item.id.fileId);
                            targetRes.map(res => {
                                res.imageUrl = url;
                            });
                            this.signFileId[item.id.fileId] = url;
                        } catch (detail) {
                            console.error('not found image');
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
            }
            response.size = this.listParam.size;
            this.pageParam = response;
        },
        pageCallback(page) {
            this.listParam.page = page;
            this.list();
        },
        contentSubStr(str) {
            if (str) {
                str = str.replace(/<[^>]*>?/g, '');
                if (str.length > 10) {
                    str = str.substr(0, 8) + '...';
                }
            }

            return str;
        },
        imgFaults(item) {
            var url = '/img/common/';
            if (item) url += 'bullet_red_icon.png';
            else url += 'bullet_green_icon.png';

            return url;
            //bullet_green_icon.png
            //bullet_red_icon.png
        },
        excel() {
            const url = '/commute/osm/book-list/excel/worker';
            this.listParam.size = null;
            this.listParam.page = 1;

            CommuteApi.excelDown(url, this.listParam);
            this.listParam.size = 10;
        },
        selectAll() {
            this.memberIds = [];
            this.allSelected = !this.allSelected;
            if (this.allSelected) {
                this.pageParam.content.forEach(element => {
                    this.memberIds.push(element.id);
                });
            }
        },
        select() {
            this.allSelected = false;
            console.log(this.memberIds);
        },
        confirm(memberIds) {
            this.confirmParam.title = '출근일지';
            this.confirmParam.msg = '확정 하시겠습니까?';
            this.confirmParam.isShow = true;
            this.confirmParam.confirm = true;
            this.memberIds = memberIds;
        },
        cancel(memberIds) {
            this.confirmParam.title = '출근일지';
            this.confirmParam.msg = '확정 취소 하시겠습니까?';
            this.confirmParam.isShow = true;
            this.confirmParam.confirm = false;
            this.memberIds = memberIds;
        },
        confirmCallback() {
            if (this.confirmParam.confirm === true) {
                CommuteApi.confirm({ memberIds: this.memberIds }).then(() => {
                    this.list();
                    this.$toast.success('저장 되었습니다.');
                });
            } else if (this.confirmParam.confirm === false) {
                CommuteApi.cancel({ memberIds: this.memberIds }).then(() => {
                    this.list();
                    this.$toast.success('저장 되었습니다.');
                });
            }
        },
        openPopup(item) {
            this.detailIsShow = true;
            this.detailItem = item;
            console.log(item);
        },
        detailCallback() {
            this.detailIsShow = false;
            this.list();
        }
    }
};
</script>
