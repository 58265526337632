<template>
    <nav class="left_menu">
        <div class="menu_inner">
            <div class="top_menu">
                <p v-show="!isManager">
                    <button
                        type="button"
                        class="menu_btn btn_01"
                        :class="menuId === 1 ? 'on' : ''"
                        @click="menuClick(1)"
                    >
                        메뉴1
                    </button>
                </p>
                <p v-show="!isManager">
                    <button
                        type="button"
                        class="menu_btn btn_02"
                        :class="menuId === 2 ? 'on' : ''"
                        @click="menuClick(2)"
                    >
                        메뉴2
                    </button>
                </p>
                <p v-show="!isManager">
                    <button
                        type="button"
                        class="menu_btn btn_04"
                        :class="menuId === 4 ? 'on' : ''"
                        @click="menuClick(4)"
                    >
                        메뉴4
                    </button>
                </p>
                <!-- <p>
                    <button
                        type="button"
                        class="menu_btn btn_03"
                        :class="menuId === 3 ? 'on' : ''"
                        @click="menuClick(3)"
                    >
                        메뉴3
                    </button>
                </p> -->

                <p>
                    <button
                        type="button"
                        class="menu_btn btn_05"
                        :class="menuId === 5 ? 'on' : ''"
                        @click="menuClick(5)"
                    >
                        메뉴5
                    </button>
                </p>
                <p>
                    <button
                        type="button"
                        class="menu_btn btn_07"
                        :class="menuId === 7 ? 'on' : ''"
                        @click="menuClick(7)"
                    >
                        메뉴7
                    </button>
                </p>

                <p>
                    <button
                        type="button"
                        class="menu_btn btn_08"
                        :class="menuId === 8 ? 'on' : ''"
                        @click="menuClick(8)"
                    >
                        메뉴8
                    </button>
                </p>

                <!-- <p>
                    <button
                        type="button"
                        class="menu_btn btn_06"
                        :class="menuId === 6 ? 'on' : ''"
                        @click="menuClick(6)"
                    >
                        CCTV
                    </button>
                </p> -->
            </div>
            <div class="bottom_menu">
                <p v-show="!isManager">
                    <button
                        type="button"
                        class="menu_btn btn_set"
                        :class="menuId === 3 ? 'on' : ''"
                        @click="menuClick(3)"
                    >
                        셋팅
                    </button>
                </p>
                <p>
                    <button type="button" class="menu_btn btn_login" @click="clickLogout">
                        로그인/로그아웃
                    </button>
                </p>
                <p>
                    <button type="button" class="menu_btn menu_arrow" @click="menuClick(0)">
                        메뉴 닫기 / 메뉴열기
                    </button>
                </p>
            </div>
        </div>
        <div v-show="subMenu" class="sub_menu">
            <menu-01 :menu-id="menuId" />
            <menu-02 :menu-id="menuId" />
            <menu-04 :menu-id="menuId" @openWorkView="openWorkView" @openNotice="openNotice" />
            <menu-06 :menu-id="menuId" />
        </div>
    </nav>
</template>
<script>
import Menu01 from './leftMenu/Menu01.vue';
import Menu02 from './leftMenu/Menu02.vue';
import Menu04 from './leftMenu/Menu04.vue';
import Menu06 from './leftMenu/Menu06.vue';
export default {
    name: 'LayoutHeader',
    components: { Menu01, Menu02, Menu04, Menu06 },
    props: {
        menuId: {
            type: Number,
            required: true
        },
        isManager: {
            type: Boolean,
            required: false
        }
    },
    data: () => {
        return {
            subMenu: true
        };
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        menuClick(menuId) {
            this.$emit('menuViewChange', menuId);
            if (menuId === 0) {
                if (this.menuId === 4 || this.menuId === 1 || this.menuId === 2 || this.menuId === 0) {
                    this.subMenu = !this.subMenu;
                }
            }
        },
        clickLogout() {
            this.$emit('onLogout');
        },
        openWorkView(item) {
            this.$emit('openWorkView', item);
        },
        openNotice(item) {
            this.$emit('openNotice', item);
        }
    }
};
</script>
