<template>
    <div :style="{ display: isShow ? 'block' : 'none' }" class="popup modify_site">
        <div class="popup_header">
            <div class="header_title">현장 업체 등록</div>
            <button @click="close" class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="popup_content">
            <div class="form_wrap">
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">업체명</p>
                        <p class="input">
                            <input v-model="param.name" type="text" placeholder="30자 이내로 작성하세요." />
                        </p>
                    </div>
                </div>

                <div class="form_item">
                    <div class="form_write">
                        <p class="title">대표자</p>
                        <p class="input">
                            <input v-model="param.representation" type="text" placeholder="30자 이내로 작성하세요." />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup_footer">
            <div class="foot_btns">
                <button type="button" class="cancel" @click="close">취소</button>
                <button type="button" class="comfirm" @click="save">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
import InfraApi from '../../api/coco/InfraApi';

export default {
    name: 'PopupModifyCompany',
    components: {},
    props: {
        isShow: {
            type: Boolean
        },
        detailItem: {
            type: Object,
            default: null
        }
    },
    data: () => {
        return {
            param: {
                name: '',
                representation: ''
            }
        };
    },
    computed: {},
    watch: {
        detailItem: function() {
            if (this.detailItem !== null) {
                this.param = this.detailItem;
            }
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        close() {
            this.param.name = '';
            this.param.representation = '';
            this.$emit('popupCallback');
        },
        save() {
            console.log(this.param.name);
            if (!this.param.name) {
                this.$toast.warning('업체명을 입력해 주세요.');
                return;
            }
            if (this.param.id) {
                InfraApi.editCompany(this.param, this.param.id).then(() => {
                    this.$toast.success('저장 되었습니다.');
                    this.$emit('popupCallback');
                });
            } else {
                InfraApi.addCompany(this.param).then(() => {
                    this.$toast.success('저장 되었습니다.');
                    this.$emit('popupCallback');
                });
            }
        }
    }
};
</script>
