<template>
    <div :style="{ display: currentPopup === POPUP_NAME.COMMUTE ? 'block' : 'none' }" class="dash_admin_wrap">
        <div class="dash_admin_inner">
            <div class="dash_admin_head">
                <p class="title">
                    근태 관리
                </p>
            </div>
            <div class="dash_admin_content">
                <div class="dash_admin_left">
                    <ul>
                        <li>
                            <button type="button" :class="tabId === 1 ? 'on' : ''" @click="tabId = 1">
                                장비기사 출근대장
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 2 ? 'on' : ''" @click="tabId = 2">
                                작업자 출근대장
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 3 ? 'on' : ''" @click="tabId = 3">
                                비정상출근자
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 4 ? 'on' : ''" @click="tabId = 4">
                                강제출근자
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 5 ? 'on' : ''" @click="tabId = 5">
                                일자별통계(시간)
                            </button>
                        </li>
                        <!-- <li>
                            <button type="button" :class="tabId === 6 ? 'on' : ''" @click="tabId = 6">
                                비정상 탑승로그
                            </button>
                        </li> -->
                        <li>
                            <button type="button" :class="tabId === 7 ? 'on' : ''" @click="tabId = 7">
                                일자별통계(작업자)
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="dash_admin_cont">
                    <dash-commute-01 :tab-id="tabId" />
                    <dash-commute-02 :tab-id="tabId" />
                    <dash-commute-03 :tab-id="tabId" />
                    <dash-commute-04 :tab-id="tabId" />
                    <dash-commute-05 :tab-id="tabId" />
                    <dash-commute-06 :tab-id="tabId" />
                    <dash-commute-07 :tab-id="tabId" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DashCommute01 from './DashCommute/DashCommute01.vue';
import DashCommute02 from './DashCommute/DashCommute02.vue';
import DashCommute03 from './DashCommute/DashCommute03.vue';
import DashCommute04 from './DashCommute/DashCommute04.vue';
import DashCommute05 from './DashCommute/DashCommute05.vue';
import DashCommute06 from './DashCommute/DashCommute06.vue';
import DashCommute07 from './DashCommute/DashCommute07.vue';

import { POPUP_NAME } from '../../const';
export default {
    name: 'PopupDashAdmin',
    components: {
        DashCommute01,
        DashCommute02,
        DashCommute03,
        DashCommute04,
        DashCommute05,
        DashCommute06,
        DashCommute07
    },
    props: {
        currentPopup: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            tabId: 1,
            POPUP_NAME: POPUP_NAME
        };
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        setMapCenter(coords) {
            this.$emit('setMapCenter', coords);
        }
    }
};
</script>
