import axios from 'axios';
import UserApi from '../../api/coco/UserApi';
import { JWT_TOKEN, JWT_REFRESH_TOKEN, HEADER_DEVICE_ID, HEADER_RE_TOKEN, USER_ID } from '../../const';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import router from '../../router';
import Qs from 'qs';
import Vue from 'vue';
const API_URL = process.env.API_URL;
const COCO_API_URL = process.env.COCO_API_URL;

export const httpLoginClient = axios.create({
    baseURL: API_URL,
    withCredentials: false
});

export const httpCoCoLoginClient = axios.create({
    baseURL: COCO_API_URL,
    withCredentials: false
});

export const httpClient = axios.create({
    baseURL: API_URL,
    withCredentials: false,
    paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })
});

export const cocoHttpClient = axios.create({
    baseURL: COCO_API_URL,
    withCredentials: false,
    paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })
});

export const uploadClient = axios.create({
    baseURL: COCO_API_URL,
    withCredentials: false,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

uploadClient.interceptors.request.use(config => {
    config.headers = {
        Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`
    };
    return config;
});
uploadClient.interceptors.response.use(undefined, async function(error) {
    //store.state.qbox.isLoading = false;
    Vue.$toast.clear();
    Vue.$toast.error('서비스 처리중 오류가 발생했습니다.');
    return Promise.reject(error);
});

export const downloadClient = axios.create({
    baseURL: COCO_API_URL,
    withCredentials: false,
    responseType: 'blob'
});

downloadClient.interceptors.request.use(config => {
    config.headers = { Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}` };
    return config;
});

export const httpClientOnlyRefresh = axios.create({
    baseURL: API_URL,
    withCredentials: false,
    timeout: 3000,
    headers: {}
});

export const excelHttpClient = axios.create({
    baseURL: COCO_API_URL,
    withCredentials: false,
    responseType: 'blob'
    // paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })
});

cocoHttpClient.interceptors.request.use(config => {
    config.headers = { Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}` };
    return config;
});

httpClient.interceptors.request.use(config => {
    config.headers = { Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}` };
    return config;
});

excelHttpClient.interceptors.request.use(config => {
    config.headers = { Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}` };
    return config;
});

let isTokenRefreshing = false;
const refreshSubscribers = [];
const onTokenRefreshed = accessToken => {
    refreshSubscribers.map(callback => callback(accessToken));
};
const addRefreshSubscriber = callback => {
    refreshSubscribers.push(callback);
};

httpClient.interceptors.response.use(undefined, async function(error) {
    const originalRequest = error.config;
    const res = error.response ? error.response : null;

    if (res.status === 401) {
        if (!isTokenRefreshing) {
            isTokenRefreshing = true;
            const reToken = await AsyncLocalStorage.getItem(JWT_REFRESH_TOKEN);
            const memberId = await AsyncLocalStorage.getItem(USER_ID);
            const { content } = await UserApi.retoken({
                memberId: memberId,
                [HEADER_DEVICE_ID]: navigator.userAgent,
                [HEADER_RE_TOKEN]: reToken
            }).catch(() => {
                router.push({ path: `/login` });
            });
            await AsyncLocalStorage.setItem(JWT_TOKEN, content.accessToken);
            await AsyncLocalStorage.setItem(JWT_REFRESH_TOKEN, content.refreshToken);
            isTokenRefreshing = false;

            axios.defaults.headers.common.Authorization = `Bearer ${content.accessToken}`;
            // 새로운 토큰으로 지연되었던 요청 진행
            onTokenRefreshed(content.accessToken);
        }
        const retryOriginalRequest = new Promise(resolve => {
            addRefreshSubscriber(accessToken => {
                originalRequest.headers.Authorization = 'Bearer ' + accessToken;
                resolve(axios(originalRequest));
            });
        });
        return retryOriginalRequest;
    } else {
        isTokenRefreshing = false;
        Vue.$toast.clear();
        if (!res) {
            Vue.$toast.error('서비스 처리중 오류가 발생했습니다.' + res.message ? res.message : '');
            return;
        }
        if (res.data.message) {
            Vue.$toast.error(res.data.message);
        } else {
            Vue.$toast.error('서비스 처리중 오류가 발생했습니다.' + res.message ? res.message : '');
        }
    }
    return Promise.reject(error);
});
