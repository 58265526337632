<template>
    <div class="header">
        <div class="header_inner">
            <div class="loogo_wrap">
                <h1 class="logo"><img src="@static/img/common/enc-logo.svg" alt="ex" /></h1>
                <p class="logo_info">{{ siteName }}</p>
            </div>
            <header-info />
        </div>
    </div>
</template>
<script>
import HeaderInfo from './header/HeaderInfo.vue';
import { SITE_NAME } from '../../const';
export default {
    name: 'LayoutHeader',
    components: { HeaderInfo },
    data: () => {
        return {
            siteName: ''
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.siteName = localStorage.getItem(SITE_NAME);
    },
    beforeDestroy() {},
    methods: {}
};
</script>
