<template>
    <div
        :style="{ display: currentPopup === POPUP_NAME.WORK_CALENDAR ? 'block' : 'none' }"
        class="sitediary_popup"
        style=""
    >
        <div class="calander_header">
            <p class="header_title">{{ siteName }} 작업일보</p>
            <p class="header_today">
                {{ selectDay }}
            </p>
            <nav class="navbar">
                <button class="button is-rounded prev" @click="onClickPrev()">
                    <img alt="prev" src="@static/img/common/arrow_prev.svg" />
                </button>
                <span class="navbar--today">{{ selectDay }}</span>
                <button class="button is-rounded next" @click="onClickNext()">
                    <img alt="prev" src="@static/img/common/arrow_fowd.svg" />
                </button>
            </nav>
        </div>
        <div class="diary_content">
            <div class="diary">
                <div class="weekdays-box">
                    <ul class="weekdays">
                        <li>Sun</li>
                        <li>Mon</li>
                        <li>Tue</li>
                        <li>Wed</li>
                        <li>Thu</li>
                        <li>Fri</li>
                        <li>Sat</li>
                    </ul>
                </div>
                <div class="days-box">
                    <ul :class="{ full: dayList.length >= 36 }">
                        <li
                            v-for="(day, index) in dayList"
                            :key="index"
                            :class="{ today: day.isCurrent === true && day.day === currentDay }"
                        >
                            <a>
                                <div class="date">
                                    <span class="dd" :style="{ color: day.isCurrent ? '' : 'grey' }">{{
                                        day.num
                                    }}</span>
                                    <span
                                        v-if="day.weather"
                                        :class="['weather ' + setDisplayType(day.weather.displayType)]"
                                    />
                                </div>
                                <div v-if="day.commute" class="total">
                                    <span class="manpower"> {{ day.commute.workerCnt }}</span>
                                    <span class="equip">{{ day.commute.equipCnt }}</span>
                                </div>
                                <div v-for="(company, idx) in day.companyList" :key="idx" class="report">
                                    <p>{{ company.name }}</p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { POPUP_NAME, SITE_NAME } from '../../const';
import { dayFormat, toMonthFormat, addMonth } from '../../utils/date';
import WeatherApi from '../../api/coco/WeatherApi.js';
import InfraApi from '../../api//coco/InfraApi';
import CommuteApi from '../../api//coco/CommuteApi';
export default {
    name: 'PopupWorkCalendar',
    components: {},
    props: {
        currentPopup: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            POPUP_NAME: POPUP_NAME,
            toDay: new Date(),
            currentYear: '',
            currentMonth: '',
            currentDay: '',
            company: { name: '', id: 0 },
            dayList: [],
            selectDay: toMonthFormat(),
            siteName: localStorage.getItem(SITE_NAME),
            todayYear: new Date().getFullYear(),
            todayMonth: new Date().getMonth()
        };
    },
    computed: {},
    created() {},
    async mounted() {
        this.init();
    },
    beforeDestroy() {},
    methods: {
        async init() {
            this.currentYear = this.toDay.getFullYear();
            this.currentMonth = this.toDay.getMonth();
            this.currentDay = dayFormat(new Date(this.todayYear, this.todayMonth, this.toDay.getDate()));

            await this.getMonthSum();
        },
        async getWeatherList() {
            const response = await WeatherApi(this.listParam);
            if (response) this.weatherList = response.content;
        },
        getMonthDays() {
            // 이전 달의 마지막 날 날짜와 요일 구하기
            const startDay = new Date(this.currentYear, this.currentMonth, 0);
            const prevDate = startDay.getDate();
            const prevDay = startDay.getDay();
            const tempList = [];

            for (let i = prevDate - prevDay; i <= prevDate; i++) {
                const day = new Date(startDay.getFullYear(), startDay.getMonth(), i);
                tempList.push({ day: dayFormat(day), num: i, isCurrent: false });
            }

            const endDay = new Date(this.currentYear, this.currentMonth + 1, 0);
            const nextDate = endDay.getDate();
            const nextDay = endDay.getDay();

            for (let i = 1; i <= nextDate; i++) {
                const day = new Date(this.currentYear, this.currentMonth, i);
                tempList.push({ day: dayFormat(day), num: i, isCurrent: true });
            }

            const nextMonth = new Date(this.currentYear, this.currentMonth + 1, 1);

            for (let i = 1; i <= (7 - nextDay === 7 ? 0 : 7 - nextDay - 1); i++) {
                const day = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), i);
                tempList.push({ day: dayFormat(day), num: i, isCurrent: false });
            }
            return tempList;
        },
        async getMonthSum() {
            let tempList = this.getMonthDays();
            const response = await CommuteApi.sumCount(
                this.company.id,
                tempList[0].day,
                tempList[tempList.length - 1].day
            );

            const companyResponse = await CommuteApi.companyList(
                this.company.id,
                tempList[0].day,
                tempList[tempList.length - 1].day
            );

            const weatherResponse = await WeatherApi.list({
                startDate: tempList[0].day.replaceAll('-', ''),
                endDate: tempList[tempList.length - 1].day.replaceAll('-', '')
            });

            tempList = tempList.map(item => {
                const data = response.find(it => item.day === it.day);
                const weather = weatherResponse.find(it => item.day === it.baseDate);
                const companyList = companyResponse.filter(it => item.day === it.day);
                return {
                    day: item.day,
                    num: item.num,
                    isCurrent: item.isCurrent,
                    commute: data,
                    weather: weather,
                    companyList: companyList || []
                };
            });
            this.dayList = tempList;
        },
        setDisplayType(displayType) {
            let icon = '';
            if (displayType === '맑음') {
                icon = 'icon1';
            } else if (displayType === '눈') {
                icon = 'icon9';
            } else if (displayType === '비') {
                icon = 'icon5';
            } else if (displayType === '흐림') {
                icon = 'icon4';
            }
            return icon;
        },
        onClickPrev() {
            this.selectDay = addMonth(this.toDay, -1);
            this.init();
        },
        onClickNext() {
            this.selectDay = addMonth(this.toDay, 1);
            this.init();
        }
    }
};
</script>
