import Vue from 'vue';
import Router from 'vue-router';

import Main from '../components/main/main';
import Login from '../components/main/login';
import Manage from '../components/main/manage';
import A from '../components/place/A';
import B from '../components/place/B';
import C from '../components/place/C';
Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Main',
            alias: '/main',
            component: Main
        },
        {
            path: '/login',
            name: 'Login',
            alias: '/login',
            component: Login
        },
        {
            path: '/manage',
            name: 'Manage',
            alias: '/manage',
            component: Manage
        },
        {
            path: '/a',
            name: 'A',
            component: A
        },
        {
            path: '/b',
            name: 'B',
            component: B
        },
        {
            path: '/c',
            name: 'C',
            component: C
        }
    ]
});

export default router;
