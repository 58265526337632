<template>
    <div :style="{ display: menuId === 6 ? 'block' : 'none' }" class="sub_menu_06">
        <div class="sub_menu_inner">
            <h2>현장 CCTV 현황</h2>
            <div class="cctv_wrap">
                <div class="cctv_list">
                    <button class="cctv_item">
                        <span class="title"><b>CCTV #1</b>강진분기점 1교</span>
                        <img src="@static/img/common/ex_img_01.png" alt="" />
                    </button>
                    <button class="cctv_item">
                        <span class="title"><b>CCTV #2</b>부채도로 8번</span>
                        <img src="@static/img/common/ex_img_01.png" alt="" />
                    </button>
                    <button class="cctv_item">
                        <span class="title"><b>CCTV #3</b>강진분기점 2교</span>
                        <img src="@static/img/common/ex_img_01.png" alt="" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Menu06',
    components: {},
    props: {
        menuId: {
            type: Number,
            required: true
        }
    },
    data: () => {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {}
};
</script>
