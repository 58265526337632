<template>
    <div class="notice_wrap">
        <div v-for="(item, index) in resultList" :key="index" class="notice_item" @click="showDetail(item)">
            <div :class="item.noticeType === 'NORMAL' ? 'info' : 'alert'" class="icon" />
            <div class="notice_info">
                <p class="title">
                    {{ item.title }}
                </p>
                <p class="date">
                    {{ item.createdAt }} <span class="write_id">{{ item.createdByName }}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import BoardApi from '../../../../api/coco/BoardApi';
export default {
    name: 'Notice',
    components: {},
    props: {
        isShow: {
            type: Boolean
        }
    },
    data: () => {
        return {
            resultList: [],
            listParam: { page: 1, size: 2, type: 'NOTICE' }
        };
    },
    computed: {},
    watch: {
        isShow: function() {
            this.list();
        }
    },
    created() {},
    mounted() {
        this.list();
    },
    beforeDestroy() {},
    methods: {
        list() {
            BoardApi.list(this.listParam).then(result => {
                this.resultList = result.content;
                this.filterList = result.content;
            });
        },
        showDetail(item) {
            this.$emit('openNotice', item);
        }
    }
};
</script>
