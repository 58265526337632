<template>
    <div :style="{ display: menuId === 2 ? 'block' : 'none' }" class="sub_menu_02">
        <div class="sub_menu_inner">
            <h2>장비 투입 현황</h2>
            <div class="sub_menu_header">
                <count-card :summary-count="summaryCount" />

                <div class="sub_nogps">
                    <p class="sub_nogps_number">{{ missingCount ? missingCount : 0 }}</p>
                    <p class="sub_nogps_title">GPS 추적 불가 장비</p>
                </div>

                <div class="toggle_wrap">
                    <div class="toggle_box">
                        <input v-model="isBounds" type="checkbox" id="optionB" />
                        <label for="optionB" class="toggle">
                            <div class="slider" />
                        </label>
                    </div>
                    <p class="toggle_title">지도 내 장비 표시</p>
                </div>
            </div>
            <div class="sub_menu_content">
                <div class="contain_wrap">
                    <div class="tab_search">
                        <input v-model="searchText" type="text" placeholder="성명/소속회사/차량번호 검색" />
                    </div>
                    <div class="contain_wrap_tab_content">
                        <equipment-content :tab-id="tabId" :search-text="searchText" :is-bounds="isBounds" />
                        <dump-truck-content :tab-id="tabId" :search-text="searchText" :is-bounds="isBounds" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CountCard from './common/CountCard.vue';
import DumpTruckContent from './Menu02/DumpTruckContent.vue';
import EquipmentContent from './Menu02/EquipmentContent.vue';
import CommuteApi from '../../../api/coco/CommuteApi';
import { mapState } from 'vuex';
export default {
    name: 'Menu02',
    components: { CountCard, EquipmentContent, DumpTruckContent },
    props: {
        menuId: {
            type: Number,
            required: true
        }
    },
    data: () => {
        return {
            tabId: 1,
            summaryCount: {
                day: 0,
                week: 0,
                month: 0
            },
            searchText: '',
            missingCount: 0,
            isBounds: false
        };
    },
    computed: {
        ...mapState({
            carList: state => state.common.carList
        })
    },
    watch: {
        menuId: function() {
            if (this.menuId === 1) {
                this.getSummary();
            }
        },
        carList: function() {
            this.missingCount = this.carList.filter(item => item.isMissing).length;
            this.summaryCount.day = this.carList.length;
        }
    },
    created() {},
    mounted() {
        this.getSummary();
    },
    beforeDestroy() {},
    methods: {
        getSummary() {
            CommuteApi.totalSummary(this.rangeWeek()).then(res => {
                this.summaryCount.week = res.content.equipCount;
            });
            CommuteApi.totalSummary(this.rangeMonth()).then(res => {
                this.summaryCount.month = res.content.equipCount;
            });
        },
        rangeWeek() {
            var now = new Date();
            var nowDayOfWeek = now.getDay();
            var nowDay = now.getDate();
            var nowMonth = now.getMonth();
            var nowYear = now.getYear();
            nowYear += nowYear < 2000 ? 1900 : 0;
            var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
            var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
            return [this.formatDate(weekStartDate), this.formatDate(weekEndDate)];
        },
        rangeMonth() {
            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            return [this.formatDate(firstDay), this.formatDate(lastDay)];
        },
        formatDate(date) {
            var year = date.getFullYear();
            var month = ('0' + (1 + date.getMonth())).slice(-2);
            var weekday = ('0' + date.getDate()).slice(-2);
            return `${year}-${month}-${weekday}`;
        }
    }
};
</script>
