import { httpClient } from '../../network/httpclient/HttpClientFactory';

const list = targetDate => httpClient.get(`/ble-device/${targetDate}`).then(res => res.data);
const save = params => httpClient.post(`/ble-device`, params).then(res => res.data);
const update = (id, params) => httpClient.put(`/ble-device/${id}`, params).then(res => res.data);
const updateBattery = (id, params) => httpClient.put(`/ble-device/${id}`, params).then(res => res.data);
const remove = id => httpClient.delete(`/ble-device/${id}`).then(res => res.data);
const bleDetailIds = params => httpClient.get(`ble-device/riding/ble-list`, { params }).then(res => res.data);
const wrongList = params => httpClient.get(`/ble-device/wrong-list`, { params }).then(res => res.data);
export default {
    list,
    save,
    update,
    updateBattery,
    remove,
    bleDetailIds,
    wrongList
};
