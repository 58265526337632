<template>
    <body>
        <layout-header />

        <div class="container" :class="menuView">
            <!--menu_open-->
            <left-menu :menu-id="menuId" :is-manager="true" @menuViewChange="menuViewChange" @onLogout="onLogout" />
        </div>

        <popup-dash-admin :current-popup="currentPopup" />
        <popup-commute-admin :current-popup="currentPopup" />
        <popup-calendar :is-manager="true" v-if="menuId === 5" :current-popup="currentPopup" />
        <popup-work-calendar :current-popup="currentPopup" />
        <popup-inspection />
        <popup-logout />
        <confirm :param="confirmParam" @confirmCallback="confirmCallback" />
    </body>
</template>
<script>
import LayoutHeader from '../layout/LayoutHeader.vue';
import LeftMenu from '../layout/LeftMenu.vue';

import PopupLogout from '../popup/PopupLogout.vue';
import PopupInspection from '../popup/PopupInspection.vue';
import PopupCalendar from '../popup/PopupCalendar.vue';

import UserApi from '../../api/coco/UserApi';
import PopupDashAdmin from '../popup/PopupDashAdmin.vue';

import PopupCommuteAdmin from '../popup/PopupCommuteAdmin.vue';

import { POPUP_NAME } from '../../const';
import PopupWorkCalendar from '../popup/PopupWorkCalendar.vue';
import Confirm from '../popup/Confirm.vue';
export default {
    name: 'Main',
    components: {
        LayoutHeader,
        LeftMenu,
        PopupLogout,
        PopupInspection,
        PopupCalendar,
        PopupDashAdmin,
        PopupWorkCalendar,
        PopupCommuteAdmin,
        Confirm
    },
    data: () => {
        return {
            map: null,
            menuView: '',
            currentPopup: POPUP_NAME.COMMUTE,
            confirmParam: {
                title: '',
                msg: '',
                isShow: false
            },
            menuId: 8,
            lastMenuId: 8
        };
    },
    computed: {},
    created() {},
    mounted() {
        document.title = 'OSM::On Site Monitoring';
    },
    beforeDestroy() {},
    methods: {
        /**
         * 좌측 메뉴에서 선택한 menuId를 받아 html class 설정
         * @param {number} menuId 활성화 Left 메뉴
         * @returns return null
         */
        menuViewChange(menuId) {
            this.menuId = menuId;

            switch (menuId) {
                case 5:
                    this.menuView = '';
                    this.currentPopup = POPUP_NAME.CALENDAR;
                    break;
                case 7:
                    this.menuView = '';
                    this.currentPopup = POPUP_NAME.WORK_CALENDAR;
                    break;
                case 8:
                    this.menuView = '';
                    this.currentPopup = POPUP_NAME.COMMUTE;
                    break;
            }
        },
        onLogout() {
            console.log('onLogout');
            this.confirmParam.title = '로그아웃';
            this.confirmParam.msg = '로그아웃 하시겠습니까?';
            this.confirmParam.isShow = true;
        },
        confirmCallback() {
            UserApi.logout();
        }
    }
};
</script>
