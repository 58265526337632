<template>
    <div :style="{ display: tabId === 2 ? 'block' : 'none' }" class="dash_admin_box dash_admin_02">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">비콘 정보 관리</p>
            <div class="admin_head_button">
                <button type="button" @click="breakdownlist">고장 의심 조회</button>
                <button type="button" class="type_blue" @click="isShow = true">비콘 등록</button>
            </div>
        </div>
        <div class="dash_admin_box_table no-page">
            <table>
                <thead>
                    <tr>
                        <th class="soltby">순번</th>
                        <th class="soltby">MAC Address</th>
                        <th class="soltby">차량번호</th>
                        <th>배터리 용량</th>
                        <th class="soltby">최종접속시간</th>
                        <th>최종위치</th>
                        <th>수정</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="resultList.length === 0">
                        <td colspan="8">검색 결과가 없습니다.</td>
                    </tr>
                    <template v-if="resultList.length !== 0">
                        <tr v-for="item in resultList" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td>{{ item.uuid }}</td>
                            <td>{{ item.carNo }}</td>
                            <td>
                                <span :class="getBatteryCls(item.battery)" class="battery"> {{ item.battery }}%</span>
                            </td>
                            <td>{{ dateFormat(item.responsedAt) }}</td>
                            <td>
                                <a
                                    v-if="item.lat && item.lng"
                                    style="cursor:pointer"
                                    @click="onClickLocation(item.lat, item.lng)"
                                >
                                    <img :src="mapIcon" />
                                </a>
                            </td>
                            <td><button type="button" class="btn_mody" @click="update(item)">수정</button></td>
                            <td><button type="button" class="btn_add" @click="remove(item.id)">삭제</button></td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <popup-modify-beacon :detail-item="detailItem" :is-show="isShow" @popupCallback="popupCallback" />
        <confirm :param="confirmParam" @confirmCallback="confirmCallback" />
    </div>
</template>
<script>
import BleDeviceApi from '../../../api/osm/BleDeviceApi';
import Confirm from '../Confirm.vue';
import PopupModifyBeacon from '../PopupModifyBeacon.vue';
import { getBatteryCls } from '../../../utils/objectUtil';
import { getToday, dateFormat } from '../../../utils/date';

export default {
    name: 'DashAdmin02',
    components: { Confirm, PopupModifyBeacon },
    props: {
        tabId: {
            type: Number,
            default: 1
        }
    },
    data: () => {
        return {
            resultList: [],
            isShow: false,
            detailItem: null,
            confirmParam: {
                title: '',
                msg: '',
                isShow: false
            },
            mapIcon: '/img/common/location_map_icon.png'
        };
    },
    computed: {},
    watch: {
        tabId: function() {
            if (this.tabId === 2) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        list() {
            BleDeviceApi.list(this.getToday()).then(result => {
                this.resultList = result;
            });
        },
        breakdownlist() {
            BleDeviceApi.list(this.getBreakdownDay()).then(result => {
                this.resultList = result;
            });
        },
        getBatteryCls: getBatteryCls,
        popupCallback() {
            this.isShow = false;
            this.list();
        },
        remove(selectId) {
            this.confirmParam.title = '비콘 정보';
            this.confirmParam.msg = '삭제 하시겠습니까?';
            this.confirmParam.isShow = true;
            this.selectId = selectId;
        },
        confirmCallback() {
            BleDeviceApi.remove(this.selectId).then(() => {
                this.list();
                this.$toast.success('삭제 되었습니다.');
            });
        },
        getToday: getToday,
        getBreakdownDay() {
            //15일이상 연락이 안오면
            var toDay = new Date();
            var date = new Date(toDay.setDate(toDay.getDate() - 15));
            var year = date.getFullYear();
            var month = ('0' + (1 + date.getMonth())).slice(-2);
            var day = ('0' + date.getDate()).slice(-2);

            return year + '-' + month + '-' + day;
        },
        update(detailItem) {
            this.isShow = true;
            this.detailItem = detailItem;
        },
        onClickLocation(latitude, longitude) {
            const url = `https://www.google.com/maps/search/${latitude}+${longitude}`;
            window.open(url, 'map');
        },
        dateFormat: dateFormat
    }
};
</script>
