import ImageApi from '../api/coco/ImageApi';

/**
 * work에 image 정보 blob 으로 바인드
 * fixme: 추후 work.id 로 내부적으로 권한 검사, 파일을 변환 가능하도록 처리함
 * @param {*} works
 * @returns
 */
export const bindWorksBlobImage = works => {
    return works.map(item => {
        item.imageUrl = null;
        if (item.imageFileId) {
            //TODO: 썸네일과 아닌 것을 구분할 필요가 있음
            ImageApi.getFirstBlob(item.imageFileId).then(res => {
                item.imageUrl = 'data:image/png;base64,' + res;
            });
        }
        return item;
    });
};

export const bindWorksBlobImageThumb = (works, thumbnail) => {
    return works.map(item => {
        item.imageUrl = null;
        if (item.imageFileId) {
            //TODO: 썸네일과 아닌 것을 구분할 필요가 있음
            ImageApi.getFirstBlob(item.imageFileId, thumbnail).then(res => {
                item.imageUrl = 'data:image/png;base64,' + res;
            });
        }
        return item;
    });
};

export const bindFirstImageThumb = async (imageFileId, thumbnail) => {
    if (imageFileId) {
        //TODO: 썸네일과 아닌 것을 구분할 필요가 있음
        const res = await ImageApi.getFirstBlob(imageFileId, thumbnail);
        if (res) return 'data:image/png;base64,' + res;
    }
};

export const bindFilesBlobImageThumb = async (files, thumbnail) => {
    return files.map(item => {
        item.imageUrl = null;

        if (item.fileId) {
            //TODO: 썸네일과 아닌 것을 구분할 필요가 있음
            ImageApi.getBlob(item.fileId, item.fileNo, thumbnail)
                .then(res => {
                    item.imageUrl = 'data:image/png;base64,' + res;
                })
                .catch(() => {
                    console.log('file not found');
                });
        }
        return item;
    });
};

export const bindImageThumb = async (fileId, fileNo, thumbnail) => {
    if (fileId && fileNo) {
        //TODO: 썸네일과 아닌 것을 구분할 필요가 있음
        const res = await ImageApi.getBlob(fileId, fileNo, thumbnail);
        return 'data:image/png;base64,' + res;
    }
};

export const base64ToBlob = data => {
    let mimeString = '';
    let raw, uInt8Array, i;

    raw = data.replace(this.rImageType, function(header, imageType) {
        mimeString = imageType;
        return '';
    });

    raw = atob(raw);
    const rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
    for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: mimeString });
};

export const urlToBlob = dataUrl => {
    const now = new Date();
    const imageName = now.getTime() + '.jpg';
    const blob = base64ToBlob(dataUrl);
    return {
        fileNo: 1,
        name: imageName,
        type: 'blob',
        dataUrl: dataUrl,
        data: blob
    };
};
