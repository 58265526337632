import { cocoHttpClient, uploadClient, downloadClient } from '../../network/httpclient/HttpClientFactory';

const list = id => cocoHttpClient.get(`/file/${id}`).then(res => res.data.content);
const upload = (param, type) => uploadClient.post(`/file/upload/${type}`, param).then(res => res.data);
const download = (fileId, fileNo) => {
    downloadClient.get(`/file/download?fileId=${fileId}&fileNo=${fileNo}`).then(res => {
        try {
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            let fileName = getFileName(res.headers['content-disposition']);
            fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
            // not IE
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = '_self';
            if (fileName) link.download = fileName;
            link.click();
        } catch (e) {
            console.error(e);
        }
    });
};

const getFileName = contentDisposition => {
    const fileName = contentDisposition
        .split(';')
        .filter(ele => {
            return ele.indexOf('filename') > -1;
        })
        .map(ele => {
            return ele.replace(/"/g, '').split('=')[1];
        });
    return fileName[0] ? fileName[0] : null;
};
export default {
    list,
    upload,
    download
};
