import { cocoHttpClient } from '../../network/httpclient/HttpClientFactory';

const getBlob = (fileId, fileNo = 1, thumbnail = null) => {
    const param = {
        fileId,
        fileNo
    };
    if (thumbnail) param.thumbnail = thumbnail;
    return cocoHttpClient.get(`/file/read/image/blob`, { params: param }).then(res => res.data.content);
};

const getFirstBlob = (fileId, thumbnail = null) => {
    const param = {
        fileId
    };
    if (thumbnail) param.thumbnail = thumbnail;
    return cocoHttpClient
        .get(`/file/read/image/blob`, { params: param })
        .then(res => res.data.content)
        .catch(err => console.log(err));
};

const getBlobList = param => cocoHttpClient.post(`/file/read/images/blobs`, param).then(res => res.data.content);

export default {
    getBlob,
    getBlobList,
    getFirstBlob
};
