<template>
    <div class="sub_menu_dash dash_wrap">
        <div class="dash_item dash_item_01">
            <div class="item_num">{{ summaryCount.day | numComma }}</div>
            <div class="item_title">금일 투입</div>
        </div>
        <div class="dash_item dash_item_02">
            <div class="item_num">{{ (summaryCount.week + summaryCount.day) | numComma }}</div>
            <div class="item_title">주간 누계</div>
        </div>
        <div class="dash_item dash_item_03">
            <div class="item_num">{{ (summaryCount.month + summaryCount.day) | numComma }}</div>
            <div class="item_title">월간 누계</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CountCard',
    components: {},
    props: {
        summaryCount: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {}
};
</script>
