<template>
    <div :style="{ display: tabId === 5 ? 'block' : 'none' }" class="dash_admin_box dash_admin_03">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">
                현장 업체 목록
            </p>
            <div class="admin_head_button">
                <button type="button" class="type_blue" @click="regist">
                    업체 등록
                </button>
            </div>
        </div>
        <div class="dash_admin_box_table">
            <table>
                <thead>
                    <tr>
                        <th>
                            순번
                        </th>
                        <th>
                            업체명
                        </th>
                        <th>
                            대표자
                        </th>
                        <th>
                            등록일
                        </th>
                        <th>수정</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="pageParam.total === 0">
                        <td colspan="7">검색 결과가 없습니다.</td>
                    </tr>
                    <template v-if="pageParam.total !== 0">
                        <tr v-for="(item, index) in pageParam.content" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>
                                {{ item.representation }}
                            </td>

                            <td>{{ item.createdAt }}</td>
                            <td>
                                <button type="button" class="btn_mody" @click="update(item)">
                                    수정
                                </button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <pagination :page-param="pageParam" :list-cnt="listParam.size" @pageCallback="pageCallback" />
        <popup-modify-company :detail-item="detailItem" :is-show="isShow" @popupCallback="popupCallback" />
    </div>
</template>
<script>
import Pagination from '../../layout/Pagination.vue';
import PopupModifyCompany from '../PopupModifyCompany.vue';
import InfraApi from '../../../api/coco/InfraApi';
export default {
    name: 'DashAdmin05',
    components: { Pagination, PopupModifyCompany },
    props: {
        tabId: {
            type: Number,
            default: 5
        }
    },
    data: () => {
        return {
            pageParam: { content: [] },
            listParam: { page: 1, size: 10 },
            isShow: false,
            detailItem: null
        };
    },
    computed: {},
    watch: {
        tabId: function() {
            if (this.tabId === 5) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        list() {
            InfraApi.getCompanyListDetail(localStorage.getItem('siteId')).then(result => {
                result.size = this.listParam.size;
                this.pageParam = result;
                console.log(result);
            });
        },
        pageCallback(page) {
            this.listParam.page = page;
            this.list();
        },
        popupCallback() {
            this.detailItem = null;
            this.isShow = false;
            this.list();
        },

        update(item) {
            this.isShow = true;
            this.detailItem = item;
        },
        regist() {
            this.isShow = true;
            this.detailItem = null;
        }
    }
};
</script>
