<template>
    <div :style="{ display: tabId === 1 ? 'block' : 'none' }" class="contain_wrap_tab_01">
        <div v-for="(item, index) in filterList" :key="index" class="name_card">
            <div :class="getMemberStatus(item)" class="name_card_vi">{{ item.name.charAt(0) }}</div>
            <div class="name_card_info">
                <p class="name_card_name">{{ item.name | mskName }}</p>
                <p class="name_card_comp">{{ item.company }}</p>
                <p class="name_card_time">누적 {{ item.totalHour | numComma }}</p>
            </div>
            <div class="name_card_checkin">
                <p :class="item.checkInTime ? 'on' : ''" class="check_in">
                    <span class="badge">출근</span> {{ item.checkInTime ? item.checkInTime : '-' }}
                </p>
                <p :class="item.checkOutTime ? 'on' : ''" class="check_out">
                    <span class="badge">퇴근</span>{{ item.checkOutTime ? item.checkOutTime : '-' }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CommuteApi from '../../../../api/coco/CommuteApi';
import { getMemberStatus } from '../../../../utils/objectUtil';
export default {
    name: 'MemberContent',
    components: {},
    props: {
        tabId: {
            type: Number,
            default: 1
        },
        searchText: {
            type: String,
            default: ''
        },
        isBounds: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            memberList: [],
            filterList: []
        };
    },
    computed: {
        ...mapState({
            workerList: state => state.common.workerList,
            mapBounds: state => state.common.mapBounds
        })
    },
    watch: {
        searchText: function() {
            this.filterFn();
        },
        workerList: function() {
            const ids = this.workerList.map(item => item.id);
            if (ids.length > 0) {
                CommuteApi.detailIds({ ids: ids }).then(res => {
                    const memberMap = new Map(
                        res.content.map(object => {
                            return [object.memberId, object];
                        })
                    );
                    this.workerList.map((item, index) => {
                        this.memberList[index] = Object.assign({}, item);
                        if (memberMap.has(item.id)) {
                            const memberDetail = memberMap.get(item.id);
                            this.memberList[index].checkInTime = this.shortTime(memberDetail.startedAt);
                            this.memberList[index].checkOutTime = this.shortTime(memberDetail.endedAt);
                            this.memberList[index].totalHour = memberDetail.totalWorkTime;
                        } else {
                            this.memberList[index].checkInTime = null;
                            this.memberList[index].checkOutTime = null;
                            this.memberList[index].totalHour = 0;
                        }
                    });
                    this.setWorkerList(this.memberList);
                    this.filterFn();
                });
            }
        },
        mapBounds: function() {
            this.setFilterBound();
        },
        isBounds: function() {
            if (this.isBounds) {
                this.setFilterBound();
            } else {
                this.filterList = this.memberList;
            }
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions(['setWorkerList']),
        getMemberStatus: getMemberStatus,
        shortTime(dateStr) {
            if (dateStr) {
                if (dateStr.length > 18) {
                    return dateStr.substr(11, 8);
                }
            }
            return dateStr;
        },
        setFilterBound() {
            if (this.isBounds) {
                if (this.mapBounds._ne) {
                    this.filterList = this.memberList;
                    this.filterList = this.filterList.filter(item => {
                        return (
                            item.lng <= this.mapBounds._ne.lng &&
                            item.lat <= this.mapBounds._ne.lat &&
                            item.lng >= this.mapBounds._sw.lng &&
                            item.lat >= this.mapBounds._sw.lat
                        );
                    });
                }
            }
        },
        filterFn() {
            if (this.searchText.length >= 1) {
                this.filterList = this.memberList.filter(
                    item => item.name.indexOf(this.searchText) > -1 || item.company.indexOf(this.searchText) > -1
                );
            } else {
                if (this.memberList.length !== this.filterList) {
                    this.filterList = this.memberList;
                }
            }
        }
    }
};
</script>
