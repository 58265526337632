<template>
    <div>
        <div class="choice_map">
            <button :class="currentBtn === 1 ? 'on' : ''" class="map_btn" @click="detail(1)">
                위성
            </button>
            <button :class="currentBtn === 2 ? 'on' : ''" class="map_btn" @click="detail(2)">
                일반
            </button>
        </div>
        <div class="choice_map second">
            <button v-for="(item, index) in bookmarks" :key="index" class="map_btn" @click="move(item)">
                <span v-if="item.orderId !== 0">{{ item.orderId }}</span>
                {{ item.name }}
            </button>
        </div>
    </div>
</template>
<script>
import CustomObjectApi from '../../../api/osm/CustomObjectApi';
export default {
    name: 'ChoiceMap',
    components: {},
    data: () => {
        return {
            currentBtn: 1,
            bookmarks: []
        };
    },
    computed: {},
    created() {},
    async mounted() {
        this.bookmarks = await CustomObjectApi.bookmarks();
    },
    beforeDestroy() {},
    methods: {
        detail(item) {
            if (item === 1) {
                if (this.currentBtn !== 1) {
                    this.currentBtn = item;
                    this.$emit('changeMapStyle', 'satellite-v9');
                }
            } else {
                if (this.currentBtn !== 2) {
                    this.currentBtn = item;
                    this.$emit('changeMapStyle', 'default');
                }
            }
        },
        move(item) {
            this.$emit('moveBookmarks', item);
        }
    }
};
</script>
