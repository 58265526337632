<template>
    <div class="sub_menu_content">
        <div class="contain_wrap">
            <div class="tab_search">
                <input v-model="searchText" type="text" placeholder="내용/성명/소속회사 검색" @input="searchList" />
            </div>
            <div class="contain_wrap_tab_content">
                <div class="contain_wrap_tab_01">
                    <div v-for="item in filterList" :key="item.id" class="work_card" @click="showDetail(item)">
                        <div class="work_card_vi">
                            <img :src="item.imageUrl | workNoImage" />
                        </div>
                        <div class="work_card_info">
                            <p class="work_card_name">
                                {{ item.content }}
                            </p>
                            <p class="work_card_date">
                                <!-- {{ mediumFormat.format(new Date(item.createAt)) }} -->
                                {{ dateFormat(item.createAt) }}
                            </p>
                            <p class="work_card_comp">
                                <img src="@static/img/common/icon_user.svg" alt="" />
                                {{ item.member.name | mskName }} {{ item.member.positionName }} /
                                {{ item.member.companyName }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { dateFormat } from '../../../../utils/date';
export default {
    name: 'WorkLogContent',
    components: {},
    props: {
        isShow: {
            type: Boolean
        },
        workId: {
            type: Number,
            default: 1
        },
        startDate: {
            type: String
        },
        endDate: {
            type: String
        }
    },
    data: () => {
        return {
            dateFormat: dateFormat,
            resultList: [],
            filterList: [],
            listSize: 50,
            searchText: '',
            mediumFormat: new Intl.DateTimeFormat('ko', { datemyle: 'medium', timeStyle: 'medium' })
        };
    },
    computed: {
        ...mapState({
            workList: state => state.common.workList
        }),
        ...mapGetters(['getLang'])
    },
    watch: {
        workList: function() {
            this.resultList = this.workList;
            this.filterList = this.workList;
        },
        workId: function() {
            this.searchList();
        },
        startDate: function() {
            this.searchList();
        },
        endDate: function() {
            this.searchList();
        }
    },
    created() {},
    mounted() {
        this.resultList = this.workList;
        this.filterList = this.workList;
    },
    beforeDestroy() {},
    methods: {
        searchList() {
            if (this.workId === 1) {
                const temp = this.resultList.filter(
                    item => item.createAt >= this.startDate && item.createAt <= this.endDate
                );
                if (this.searchText.length >= 1) {
                    this.filterList = temp.filter(
                        item =>
                            item.member.name.indexOf(this.searchText) > -1 ||
                            item.member.companyName.indexOf(this.searchText) > -1 ||
                            item.content.indexOf(this.searchText) > -1
                    );
                } else {
                    this.filterList = temp;
                }
            } else if (this.workId === 2) {
                const temp = this.resultList.filter(
                    item => item.finishDt !== null && item.createAt >= this.startDate && item.createAt <= this.endDate
                );
                if (this.searchText.length >= 1) {
                    this.filterList = temp.filter(
                        item =>
                            item.member.name.indexOf(this.searchText) > -1 ||
                            item.member.companyName.indexOf(this.searchText) > -1 ||
                            item.content.indexOf(this.searchText) > -1
                    );
                } else {
                    this.filterList = temp;
                }
            } else if (this.workId === 3) {
                const temp = this.resultList.filter(
                    item => item.finishDt === null && item.createAt >= this.startDate && item.createAt <= this.endDate
                );
                if (this.searchText.length >= 1) {
                    this.filterList = temp.filter(
                        item =>
                            item.member.name.indexOf(this.searchText) > -1 ||
                            item.member.companyName.indexOf(this.searchText) > -1 ||
                            item.content.indexOf(this.searchText) > -1
                    );
                } else {
                    this.filterList = temp;
                }
            }
        },
        showDetail(item) {
            console.log(this.workId);
            this.$emit('openPopup', item);
        }
    }
};
</script>
