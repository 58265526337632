<template>
    <div :style="{ display: tabId === 2 ? 'block' : 'none' }" class="contain_wrap_tab_02">
        <div v-for="(item, index) in filterList" :key="index" class="name_card no_gps">
            <div class="name_card_vi">{{ item.name.charAt(0) }}</div>
            <div class="name_card_info">
                <p class="name_card_name">{{ item.name | mskName }}</p>
                <p class="name_card_comp">{{ item.company }}</p>
                <p class="name_card_time"><span class="badge red">접속</span>{{ item.lastDateTime }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: 'MemberContentNoGps',
    components: {},
    props: {
        tabId: {
            type: Number,
            default: 1
        },
        searchText: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            filterList: []
        };
    },
    computed: {
        ...mapState({
            workerList: state => state.common.workerList
        })
    },
    watch: {
        searchText: function() {
            if (this.searchText.length >= 1) {
                this.filterList = this.workerList.filter(
                    item => item.name.indexOf(this.searchText) > -1 || item.company.indexOf(this.searchText) > -1
                );
            } else {
                if (this.workerList.length !== this.filterList) {
                    this.filterList = this.workerList;
                }
            }
        },
        workerList: function() {
            this.filterList = this.workerList.filter(item => item.isMissing);
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {}
};
</script>
