<template>
    <div :style="{ display: tabId === 7 ? 'block' : 'none' }" class="dash_admin_box dash_commute_05">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">
                업체별 출근통계
            </p>
        </div>

        <!-- 검색 Wrap -->
        <div class="option_wrap">
            <div class="search_filter">
                <form>
                    <div class="option_date">
                        <date-picker
                            id="startedDate"
                            v-model="condition.beginDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                        <span>~</span>
                        <date-picker
                            id="endedDate"
                            v-model="condition.endDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                    </div>

                    <select v-show="authType === 'ADMIN'" v-model="condition.companyId" class="search_option">
                        <option value="">
                            선택
                        </option>
                        <option v-for="(code, index) in companyList" :key="index" :value="code.id">
                            {{ code.name }}
                        </option>
                    </select>

                    <div class="search_button">
                        <button type="button" @click="onClickSearch">
                            검색
                        </button>
                    </div>
                </form>
            </div>
            <div class="right_area">
                <div class="download_button">
                    <button type="button" @click="excel">
                        엑셀 다운로드
                    </button>
                </div>
            </div>
        </div>
        <!-- 검색 Wrap //-->
        <div id="container" class="graph_wrap">
            <v-chart class="chart" :option="option" autoresize />
        </div>

        <!-- 전체 통계 wrap -->
        <div class="stat_wrap">
            <div class="item">
                작업자 투입 합계<span>{{ dailyStats.summary.totalWorkCnt }}명</span>
            </div>
            <div class="item">
                장비 투입 합계<span>{{ dailyStats.summary.totalEquipCnt }}명</span>
            </div>
        </div>
        <!-- 전체 통계 wrap //-->

        <div class="dash_admin_box_table graph-page">
            <table>
                <thead>
                    <tr>
                        <th class="tt1">
                            근무일
                        </th>
                        <th class="tt1">
                            업체명
                        </th>
                        <th class="tt2">
                            작업자
                        </th>
                        <th class="tt2">
                            장비
                        </th>
                        <th class="tt3">
                            비정상
                        </th>
                    </tr>
                    <tr>
                        <th>(년,월,일)</th>
                        <th>(협력업체)</th>
                        <th>일반근무(명)</th>
                        <th>추가근무(명)</th>
                        <th>반일근무(명)</th>
                        <th>일반근무(명)</th>
                        <th>추가근무(명)</th>
                        <th>반일근무(명)</th>
                        <th>출근</th>
                        <th>퇴근</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="dailyStats.stats.length === 0">
                        <td colspan="10">
                            검색 결과가 없습니다.
                        </td>
                    </tr>
                    <template v-if="dailyStats.stats.length !== 0">
                        <tr v-for="(item, index) in dailyStats.stats" :key="index">
                            <td>{{ item.day }}</td>
                            <td>{{ item.companyName }}</td>
                            <td>{{ item.workerNormalCnt | numComma }}</td>
                            <td>{{ item.workerAddCnt | numComma }}</td>
                            <td>{{ item.workerHalfCnt }}</td>
                            <td>{{ item.equipNormalCnt }}</td>
                            <td>{{ item.equipAddCnt | numComma }}</td>
                            <td>{{ item.equipHalfCnt | numComma }}</td>
                            <td>{{ item.startedFaultCnt }}</td>
                            <td>{{ item.endedFaultCnt }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import CommuteApi from '../../../api/coco/CommuteApi';
import InfraApi from '../../../api/coco/InfraApi';
import { getToday, toDayFormatAddMonth } from '../../../utils/date';

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart, BarChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { AUTH_TYPE } from '../../../const';
import { mapGetters } from 'vuex';
use([CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, GridComponent, LineChart, BarChart]);

export default {
    name: 'DashCommute07',
    components: { VChart, DatePicker },
    provide: {
        [THEME_KEY]: 'dark'
    },
    props: {
        tabId: {
            type: Number,
            default: 7
        }
    },
    data: () => {
        return {
            dailyStats: { stats: [], summary: {} },
            condition: { companyId: '', beginDate: toDayFormatAddMonth(-1), endDate: getToday() },
            companyList: [],
            isShow: false,
            xAxisData: [],
            yAxisData: [],
            yWorkerCounts: [],
            yEquipCounts: [],
            option: {},
            authType: localStorage.getItem(AUTH_TYPE),
            yNormalWorkerCount: [],
            yHalfWorkerCount: [],
            yAddWorkerCount: [],
            yNormalEquipCounts: [],
            yHalfEquipCounts: [],
            yAddEquipCounts: []
        };
    },
    computed: { ...mapGetters(['getLang']) },
    watch: {
        tabId: function() {
            if (this.tabId === 7) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {
        InfraApi.companyList().then(res => {
            this.companyList = res;
        });
    },
    beforeDestroy() {},
    methods: {
        onClickSearch() {
            this.list();
        },
        async list() {
            const response = await CommuteApi.dailyStatsCount(this.condition);
            console.log(response.content);
            if (response) {
                this.dailyStats = response.content;
                const workers = [];
                const equips = [];
                this.xAxisData = [];
                for (var item of this.dailyStats.stats) {
                    this.xAxisData.push(item.day);
                    workers.push({
                        day: item.day,
                        normalCount: item.workerNormalCnt,
                        halfCount: item.workerHalfCnt,
                        addCount: item.workerAddCnt
                    });
                    equips.push({
                        day: item.day,
                        normalCount: item.equipNormalCnt,
                        halfCount: item.equipHalfCnt,
                        addCount: item.equipAddCnt
                    });
                }

                // x distinct
                const set = new Set(this.xAxisData);
                const xData = [...set];
                this.xAxisData = Array.from(xData[0]);
                // this.xAxisData = xData;

                (this.yNormalWorkerCount = []),
                    (this.yHalfWorkerCount = []),
                    (this.yAddWorkerCount = []),
                    (this.yNormalEquipCounts = []),
                    (this.yHalfEquipCounts = []),
                    (this.yAddEquipCounts = []);
                for (var day of this.xAxisData) {
                    const data = workers.filter(item => item.day === day);
                    const sumNormalWorker = data.reduce((previous, current) => previous + current.normalCount, 0);

                    // 작업자
                    this.yNormalWorkerCount.push(sumNormalWorker);

                    const sumHalfWorker = data.reduce((previous, current) => previous + current.halfCount, 0);

                    this.yHalfWorkerCount.push(sumHalfWorker);

                    const sumAddWorker = data.reduce((previous, current) => previous + current.addCount, 0);

                    this.yAddWorkerCount.push(sumAddWorker);

                    // 장비
                    const equipData = equips.filter(item => item.day === day);

                    const sumNormalEquip = equipData.reduce((previous, current) => previous + current.normalCount, 0);

                    this.yNormalEquipCounts.push(sumNormalEquip);

                    const sumHalfEquip = equipData.reduce((previous, current) => previous + current.halfCount, 0);

                    this.yHalfEquipCounts.push(sumHalfEquip);

                    const sumAddEquip = equipData.reduce((previous, current) => previous + current.addCount, 0);

                    this.yAddEquipCounts.push(sumAddEquip);
                }

                this.setup();
            }
        },
        contentSubStr(str) {
            if (str) {
                str = str.replace(/<[^>]*>?/g, '');
                if (str.length > 10) {
                    str = str.substr(0, 8) + '...';
                }
            }

            return str;
        },
        displayFault(isFault) {
            return isFault ? '비정상' : '정상';
        },
        addBarSeries(name, data) {
            return {
                data: data,
                type: 'bar',
                barWidth: '10%',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.15)'
                }
            };
        },
        setup() {
            this.option = {
                grid: {
                    top: 20,
                    bottom: 25,
                    left: 30,
                    right: 0
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: this.xAxisData,
                    axisLabel: {
                        // X축 폰트 크기
                        fontSize: 11,
                        color: 'rgba(255, 255, 255, 0.5)'
                    },
                    axisLine: {
                        // X축 라인 컬러
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.2)'
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        // Y축 폰트 크기
                        fontSize: 11,
                        color: 'rgba(255, 255, 255, 0.5)'
                    },
                    splitLine: {
                        // 차트 중간선 컬러
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.1)'
                        }
                    }
                },
                series: [
                    {
                        data: this.yNormalWorkerCount,
                        type: 'bar',
                        stack: 'worker',
                        name: '작업자(일반)',
                        barWidth: '10%',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.15)'
                        },
                        // itemStyle: {
                        //     color: 'rgba(100, 255, 1, 0.6)'
                        // },
                        emphasis: {
                            focus: 'series'
                        }
                    },
                    {
                        data: this.yHalfWorkerCount,
                        type: 'bar',
                        stack: 'worker',
                        name: '작업자(반일)',
                        barWidth: '10%',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.15)'
                        },
                        // itemStyle: {
                        //     color: 'rgba(100, 255, 1, 0.6)'
                        // },
                        emphasis: {
                            focus: 'series'
                        }
                    },
                    {
                        data: this.yAddWorkerCount,
                        type: 'bar',
                        stack: 'worker',
                        name: '작업자(추가)',
                        barWidth: '10%',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.15)'
                        },
                        // itemStyle: {
                        //     color: 'rgba(100, 255, 1, 0.6)'
                        // },
                        emphasis: {
                            focus: 'series'
                        }
                    },

                    {
                        data: this.yNormalEquipCounts,
                        type: 'bar',
                        stack: 'equip',
                        name: '장비(일반)',
                        barWidth: '10%',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.15)'
                        },

                        emphasis: {
                            focus: 'series'
                        }
                    },
                    {
                        data: this.yHalfEquipCounts,
                        type: 'bar',
                        stack: 'equip',
                        name: '장비(반일)',
                        barWidth: '10%',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.15)'
                        },

                        emphasis: {
                            focus: 'series'
                        }
                    },
                    {
                        data: this.yAddEquipCounts,
                        type: 'bar',
                        stack: 'equip',
                        name: '장비(추가)',
                        barWidth: '10%',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.15)'
                        },

                        emphasis: {
                            focus: 'series'
                        }
                    }
                ]
            };
        },
        excel() {
            const url = '/commute/osm/book-list/excel/statisticsCount';
            CommuteApi.excelDown(url, this.condition);
        }
    }
};
</script>
