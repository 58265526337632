<template>
    <div :style="{ display: isShow ? 'block' : 'none' }" class="popup modify_site">
        <div class="popup_header">
            <div class="header_title">현장 직원 정보</div>
            <button @click="close" class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="popup_content">
            <div class="form_wrap">
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">업체명</p>
                        <p class="title">
                            {{ param.companyName }}
                        </p>
                    </div>
                </div>

                <div class="form_item">
                    <div class="form_write">
                        <p class="title">성명</p>
                        <p class="input">
                            <input v-model.trim="param.name" type="text" placeholder="30자 이내로 작성하세요." />
                        </p>
                    </div>
                </div>

                <div class="form_item">
                    <div class="form_write">
                        <p class="title">직위</p>
                        <p class="input">
                            <select v-model="param.positionCode" class="search_option">
                                <option :value="null">
                                    선택
                                </option>
                                <option v-for="(item, index) in getPosition" :key="index" :value="item.code">
                                    {{ item.name }}
                                </option>
                            </select>
                        </p>
                    </div>
                </div>

                <div class="form_item">
                    <div class="form_write">
                        <p class="title">장비 종류</p>
                        <p class="input">
                            <select v-model="param.driverType" class="search_option">
                                <option :value="null">
                                    선택
                                </option>
                                <option v-for="(item, index) in equipList" :key="index" :value="item.type">
                                    {{ item.name }}
                                </option>
                            </select>
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">차량 번호 (장비)</p>
                        <p class="input">
                            <input v-model.trim="param.carNo" type="text" placeholder="30자 이내로 작성하세요." />
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">비밀번호</p>
                        <p class="input">
                            <input
                                v-model.trim="param.password1"
                                type="password"
                                placeholder="비밀번호 변경시 작성하세요."
                            />
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">비밀번호 확인</p>
                        <p class="input">
                            <input
                                id="password2"
                                v-model.trim="param.password2"
                                type="password"
                                placeholder="비밀번호 변경시 작성하세요."
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup_footer">
            <div class="foot_btns">
                <button type="button" class="cancel" @click="close">취소</button>
                <button type="button" class="comfirm" @click="save">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
import InfraApi from '../../api/coco/InfraApi';
import MemberApi from '../../api/coco/MemberApi';
import { mapGetters } from 'vuex';

export default {
    name: 'PopupModifyMember',
    components: {},
    props: {
        isShow: {
            type: Boolean
        },
        detailItem: {
            type: Object,
            default: null
        }
    },
    data: () => {
        return {
            param: {
                password1: '',
                password2: ''
            },
            equipList: []
        };
    },
    computed: {
        ...mapGetters(['getPosition'])
    },
    watch: {
        detailItem: function() {
            if (this.detailItem !== null) {
                this.param = this.detailItem;
            }
        }
    },
    created() {},
    mounted() {
        const siteId = localStorage.getItem('siteId');
        InfraApi.equipList(siteId).then(res => {
            this.equipList = res.content;
        });
    },
    beforeDestroy() {},
    methods: {
        close() {
            this.$emit('popupCallback');
        },
        save() {
            if (!this.param.name) {
                this.$toast.warning('성명을 입력 해주세요.');
                console.log(this.param.name);
                return;
            }

            if (this.param.password1 !== this.param.password2) {
                console.log(this.param.password1 !== this.param.password2);
                console.log(this.param.password1);
                console.log(this.param.password2);
                this.$toast.warning('비밀번호를 확인 해주세요.');
                return;
            }

            if (!this.param.carNo) {
                this.param.carNo = null;
            }
            MemberApi.updateMember(this.param, this.param.id).then(() => {
                this.$toast.success('저장 되었습니다.');
                this.$emit('popupCallback');
                this.param.password1 = '';
                this.param.password2 = '';
            });
        }
    }
};
</script>
