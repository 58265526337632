<template>
    <router-view :key="$route.fullPath" />
</template>

<script>
export default {
    name: 'App',
    components: {},
    data: () => {
        return {
            bodyCls: ''
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    destroyed() {},
    methods: {}
};
</script>
