<template>
    <div class="header_info">
        <p :class="weatherCls" class="info_weather" />
        <p class="info_date">{{ weather.tmp }}℃</p>
        <p class="info_date">미세먼지· {{ air.pm10Value }}㎍/㎥</p>
        <!-- 맑음 -->
        <!-- <p class="info_weather icon2"></p> -- 구름조금 -->
        <!-- <p class="info_weather icon3"></p> -- 구름많음 -->
        <!-- <p class="info_weather icon4"></p> -- 흐림 -->
        <!-- <p class="info_weather icon5"></p> -- 비 -->
        <!-- <p class="info_weather icon6"></p> -- 소낙비 -->
        <!-- <p class="info_weather icon7"></p> -- 뇌우/폭우 -->
        <!-- <p class="info_weather icon8"></p> -- 눈비 -->
        <!-- <p class="info_weather icon9"></p> -- 눈 -->
        <!-- <p class="info_weather icon10"></p> -- 소낙눈 -->
        <!-- <p class="info_weather icon11"></p> -- 안개/황사 -->
        <p class="info_date">{{ htmlClock }}</p>
        <div class="info_number info_disaster">
            <p class="title">무재해 달성</p>
            <p class="num">{{ accidentCount }}<span>일</span></p>
        </div>
        <div class="info_number info_process">
            <p class="title">공정률</p>
            <p class="num">{{ progress }}<span>%</span></p>
        </div>
    </div>
</template>
<script>
import SiteCommonApi from '../../../api/osm/SiteCommonApi';
import WeatherApi from '../../../api/coco/WeatherApi';
import { getToday } from '../../../utils/date';
export default {
    name: 'HeaderInfo',
    components: {},
    data: () => {
        return {
            htmlClock: '',
            accidentCount: 0,
            progress: 0,
            weatherCls: 'icon1',
            weather: {
                tmn: '-',
                tmx: '-'
            },
            air: { pm10Value: '' }
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getTime();
        setInterval(this.getTime, 1000);

        SiteCommonApi.getAccident().then(result => {
            this.accidentCount = result;
        });
        SiteCommonApi.getProgress().then(result => {
            this.progress = result.toFixed(1);
        });

        this.getWeather();
        setInterval(() => {
            this.getWeather();
        }, 1000 * 60 * 60); //1 시간 단위
    },
    beforeDestroy() {},
    methods: {
        getToday: getToday,
        async getWeather() {
            const result = await WeatherApi.day(this.getToday().replaceAll('-', ''));
            if (result) {
                this.weather = result.content;
            }
            const airResult = await WeatherApi.air(this.getToday());
            if (airResult) {
                this.air = airResult.content;
            }

            if (result.content) {
                const displayType = result.content.displayType;
                if (displayType === '맑음') {
                    this.weatherCls = 'icon1';
                } else if (displayType === '흐림') {
                    this.weatherCls = 'icon4';
                } else if (displayType === '눈') {
                    this.weatherCls = 'icon9';
                } else if (displayType === '비') {
                    this.weatherCls = 'icon5';
                }
            }
        },
        getTime() {
            const date = new Date();
            const week = new Array('[일]', '[월]', '[화]', '[수]', '[목]', '[금]', '[토]');
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const dayName = week[date.getDay()];

            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            this.htmlClock = `${month < 10 ? `0${month}` : month}.${day < 10 ? `0${day}` : day}${dayName}
    	    ${hours < 10 ? `0${hours}` : hours}:
            ${minutes < 10 ? `0${minutes}` : minutes}:
            ${seconds < 10 ? `0${seconds}` : seconds}`;
        }
    }
};
</script>
