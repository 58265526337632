<template>
    <div class="full_popup cctv_popup">
        <div class="full_popup_head">
            <p class="title cctv_title">CCTV PLAYER</p>
            <div class="cctv_tab_btn">
                <button type="button" class="on">CCTV #1</button>
                <button type="button">CCTV #2</button>
                <button type="button">CCTV #3</button>
            </div>
            <button class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="full_popup_content">
            <div class="cctv_tab_content">
                <div class="cctv_title">강진분기점 1교</div>
                <div class="cctv_player">
                    <img src="@static/img/common/ex_cctv.png" alt="예시" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PopupCctv',
    components: {},
    data: () => {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {}
};
</script>
