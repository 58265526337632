import { mskName } from '../../utils/string';

import { DRIVER_TYPE, WORK_TYPE } from '../../const';

const getDriverType = type => DRIVER_TYPE[type];
const getWorkType = type => WORK_TYPE[type];

const VueStringFilter = {
    install(Vue) {
        Vue.filter('mskName', mskName);
        Vue.filter('getDriverType', getDriverType);
        Vue.filter('getWorkType', getWorkType);
    }
};

export default VueStringFilter;
