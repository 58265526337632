export const APPLICATION_VERSION = process.env.VERSION;
export const APPLICATION_PHASE = process.env.APPLICATION_PHASE || 'REAL';
export const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoiYW5kdGhlYmVlIiwiYSI6ImNsYWFyYjZoZDA5dTAzc3J6dHdoNjFtYmMifQ.5K1rLqbH-2HRzi-Ta5Zxyg';
export const MAPBOX_MAP_STYLE = 'mapbox://styles/andthebee/clac74ycx000515s02xju213f';
export const MAPBOX_MAP_STYLE_SATELLITE = 'mapbox://styles/andthebee/clac7368u000614mipr4qoc10';
export const POPUP_NAME = {
    ADMIN: 'dash_admin',
    CALENDAR: 'calendar',
    WORK_CALENDAR: 'workCalendar',
    COMMUTE: 'commute'
};

//LocalStorage
export const JWT_TOKEN = 'token';
export const JWT_REFRESH_TOKEN = 'refreshtoken';
export const AUTH_TYPE = 'authType';
export const USER_ID = 'userId';
export const SITE_NAME = 'siteName';
export const DEV_LEVEL = 'devLevel';
export const COMPANY_NAME = 'companyName';
export const COMPANY_LIST = 'companyList';
export const HEADER_DEVICE_ID = 'Device-Id';
export const HEADER_RE_TOKEN = 'X-Refresh-Token';
export const SITE_ID = 'siteId';

export const DRIVER_TYPE = {
    DUMP: '덤프트럭',
    ROLLER: '다짐장비',
    FORK_CRANE: '굴착기',
    FORK_LIFT: '지게차',
    BULLDOZER: '불도저',
    TOWER_CRANE: '타워크레인',
    BOAT: '선박',
    BARGE: '바지선',
    ETC: '기타',
    CRANE: '크레인',
    BORING: '천공기',
    PILE_DRIVER: '항타기',
    PILE_EXTRACTOR: '항발기',
    CAR_CRANE: '카고크레인'
};

export const WORK_TYPE = {
    NORMAL: '일반근무',
    ADD: '추가근무',
    HALF: '반일근무'
};

export const LABEL_COLOR = ['#0f287d', '#7b240e', '#7b690e', '#2a7b0e', '#0e7b78', '#620e7b'];
