<template>
    <div :style="{ display: tabId === 6 ? 'block' : 'none' }" class="dash_admin_box dash_admin_03">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">
                현장 직원 목록
            </p>
            <div v-show="authType === 'ADMIN'" class="admin_head_button">
                <button type="button" class="type_blue" @click="regist">
                    직원 등록
                </button>
            </div>
        </div>
        <div class="option_wrap">
            <div class="search_filter">
                <form>
                    <select v-show="authType === 'ADMIN'" v-model="listParam.word" class="search_option">
                        <option v-for="(code, index) in companyList" :key="index" :value="code.name">
                            {{ code.name }}
                        </option>
                    </select>

                    <div class="option_date w100">
                        <input
                            v-model="listParam.name"
                            type="text"
                            placeholder="성명 검색"
                            @keyup.enter="onClickSearch"
                        />
                    </div>

                    <div class="search_button">
                        <button type="button" @click="onClickSearch">
                            검색
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="dash_admin_box_table">
            <table>
                <thead>
                    <tr>
                        <th>
                            순번
                        </th>
                        <th>
                            업체명
                        </th>
                        <th>
                            성명
                        </th>
                        <th>
                            직위
                        </th>
                        <th>
                            차량 종류
                        </th>
                        <th>
                            차량 번호
                        </th>
                        <th>
                            수정
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="pageParam.total === 0">
                        <td colspan="7">검색 결과가 없습니다.</td>
                    </tr>
                    <template v-if="pageParam.total !== 0">
                        <tr v-for="(item, index) in pageParam.content" :key="index">
                            <td>{{ pageParam.total - ((listParam.page - 1) * listParam.size + index) }}</td>
                            <td>{{ item.companyName }}</td>
                            <td>
                                {{ item.name }}
                            </td>

                            <td>{{ item.positionName }}</td>
                            <td>{{ item.driverType | getDriverType }}</td>
                            <td>{{ item.carNo }}</td>
                            <td>
                                <button type="button" class="btn_mody" @click="update(item)">
                                    수정
                                </button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <pagination :page-param="pageParam" :list-cnt="listParam.size" @pageCallback="pageCallback" />
        <popup-modify-member :detail-item="detailItem" :is-show="isShow" @popupCallback="popupCallback" />
        <popup-create-member
            :company-list="companyList"
            :is-show-create="isShowCreate"
            @popupCallback="popupCallback"
        />
    </div>
</template>
<script>
import Pagination from '../../layout/Pagination.vue';
import PopupModifyMember from '../PopupModifyMember.vue';
import PopupCreateMember from '../PopupCreateMember.vue';
import InfraApi from '../../../api/coco/InfraApi';
import { AUTH_TYPE } from '../../../const';
import MemberApi from '.././../../api/coco/MemberApi';
export default {
    name: 'DashAdmin06',
    components: { Pagination, PopupModifyMember, PopupCreateMember },
    props: {
        tabId: {
            type: Number,
            default: 6
        }
    },
    data: () => {
        return {
            pageParam: { content: [] },
            listParam: { page: 1, size: 10, word: '', name: '' },
            isShow: false,
            isShowCreate: false,
            detailItem: null,
            companyList: [],
            authType: localStorage.getItem(AUTH_TYPE)
        };
    },
    computed: {},
    watch: {
        tabId: function() {
            if (this.tabId === 6) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {
        InfraApi.companyList().then(res => {
            this.companyList = res;
            console.log(this.companyList);
            this.companyList.forEach(element => {
                if (element.name === '대우건설') {
                    this.listParam.word = element.name;
                }
            });
        });
    },
    beforeDestroy() {},
    methods: {
        onClickSearch() {
            this.listParam.page = 1;
            this.list();
        },
        list() {
            MemberApi.memberList(this.listParam).then(result => {
                result.size = this.listParam.size;
                this.pageParam = result;

                console.log(result);
            });
        },
        pageCallback(page) {
            this.listParam.page = page;
            this.list();
        },
        popupCallback() {
            this.detailItem = null;
            this.isShowCreate = false;
            this.isShow = false;
            this.list();
        },

        update(item) {
            this.isShow = true;
            item.password1 = '';
            item.password2 = '';
            this.detailItem = item;
        },
        regist() {
            this.isShowCreate = true;
        }
    }
};
</script>
