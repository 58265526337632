import { cocoHttpClient } from '../../network/httpclient/HttpClientFactory';

const driverList = () => cocoHttpClient.get(`/member/driver-list`).then(res => res.data);
const memberList = params => cocoHttpClient.get(`/member/search`, { params }).then(res => res.data);
const updateMember = (params, id) => cocoHttpClient.put(`/member/osm/${id}`, params).then(res => res.data);

export default {
    driverList,
    memberList,
    updateMember
};
