<template>
    <div :style="{ display: tabId === 4 ? 'block' : 'none' }" class="dash_admin_box dash_admin_04">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">현장 일정 관리 (캘린더 등록)</p>
            <div class="admin_head_button">
                <button type="button" class="type_blue" @click="openPopup">일정 등록</button>
            </div>
        </div>
        <div class="dash_admin_box_table">
            <table>
                <thead>
                    <tr>
                        <th class="soltby">순번</th>
                        <th class="soltby">현장 일정</th>
                        <th class="soltby">시작일</th>
                        <th class="soltby">종료일</th>
                        <th>수정</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="pageParam.total === 0">
                        <td colspan="7">검색 결과가 없습니다.</td>
                    </tr>
                    <template v-if="pageParam.total !== 0">
                        <tr v-for="item in pageParam.content" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td class="left_align">
                                <div class="badge_wrap">
                                    <div class="badge_wrap">
                                        <span :class="item.labelClass" class="color_badge" /> {{ item.title }}
                                    </div>
                                </div>
                            </td>
                            <td>{{ item.startDate }}</td>
                            <td>{{ item.endDate }}</td>
                            <td><button type="button" class="btn_mody" @click="update(item)">수정</button></td>
                            <td><button type="button" class="btn_add" @click="remove(item.id)">삭제</button></td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <pagination :page-param="pageParam" :list-cnt="listParam.size" @pageCallback="pageCallback" />
        <popup-modify-site :detail-item="detailItem" :is-show="detailIsShow" @detailCallback="detailCallback" />
        <confirm :param="confirmParam" @confirmCallback="confirmCallback" />
    </div>
</template>
<script>
import DailyLogApi from '../../../api/osm/DailyLogApi';
import Pagination from '../../layout/Pagination.vue';
import Confirm from '../Confirm.vue';
import PopupModifySite from '../PopupModifySite.vue';
export default {
    name: 'DashAdmin04',
    components: { Pagination, Confirm, PopupModifySite },
    props: {
        tabId: {
            type: Number,
            default: 1
        }
    },
    data: () => {
        return {
            pageParam: { content: [] },
            listParam: { page: 1, size: 10 },
            selectId: null,
            detailItem: null,
            detailIsShow: false,
            confirmParam: {
                title: '',
                msg: '',
                isShow: false
            }
        };
    },
    computed: {},
    watch: {
        tabId: function() {
            if (this.tabId === 4) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        list() {
            DailyLogApi.list(this.listParam).then(result => {
                result.size = this.listParam.size;
                this.pageParam = result;
            });
        },
        detailCallback() {
            this.detailIsShow = false;
            this.list();
        },
        pageCallback(page) {
            this.listParam.page = page;
            this.list();
        },
        remove(selectId) {
            this.confirmParam.title = '공지사항';
            this.confirmParam.msg = '삭제 하시겠습니까?';
            this.confirmParam.isShow = true;
            this.selectId = selectId;
        },
        confirmCallback() {
            DailyLogApi.remove(this.selectId).then(() => {
                this.list();
                this.$toast.success('삭제 되었습니다.');
            });
        },
        update(detailItem) {
            this.detailIsShow = true;
            this.detailItem = detailItem;
        },
        openPopup() {
            this.detailIsShow = true;
            const detailItem = { labelClass: 'blue', startDate: '', endDate: '', content: '', title: '' };
            this.detailItem = detailItem;
        }
    }
};
</script>
