import { cocoHttpClient } from '../../network/httpclient/HttpClientFactory';

const list = params => cocoHttpClient.get(`/board`, { params }).then(res => res.data);
const detail = id => cocoHttpClient.get(`/board/${id}`).then(res => res.data);
const save = params => cocoHttpClient.post(`/board`, params).then(res => res.data);
const update = (id, params) => cocoHttpClient.put(`/board/${id}`, params).then(res => res.data);
const remove = id => cocoHttpClient.delete(`/board/${id}`).then(res => res.data);

export default {
    list,
    detail,
    save,
    remove,
    update
};
