<template>
    <div :style="{ display: isShowCreate ? 'block' : 'none' }" class="popup modify_site">
        <div class="popup_header">
            <div class="header_title">현장 직원 등록</div>
            <button @click="close" class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="popup_content">
            <div class="form_wrap">
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">분류</p>
                        <p class="input">
                            <label class="custom_radio  ">
                                <input v-model="type" type="radio" name="modi_color_01" value="MANAGER" />
                                <span>관리자</span>
                            </label>
                            <label class="custom_radio ">
                                <input v-model="type" type="radio" name="modi_color_01" value="WORKER" />
                                <span> 일반 근로자</span>
                            </label>
                            <label class="custom_radio ">
                                <input v-model="type" type="radio" name="modi_color_01" value="DRIVER" />
                                <span>장비 기사</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">성명</p>
                        <p class="input">
                            <input v-model.trim="param.name" type="text" placeholder="30자 이내로 작성하세요." />
                        </p>
                    </div>
                </div>

                <div class="form_item">
                    <div class="form_write">
                        <p class="title">휴대폰 번호</p>
                        <p class="input">
                            <input
                                v-model.trim="param.hp"
                                type="text"
                                placeholder="(-) 제외한 번호를 입력하세요 "
                                maxlength="11"
                            />
                        </p>
                    </div>
                </div>

                <div class="form_item">
                    <div class="form_write">
                        <p class="title">비밀번호</p>
                        <p class="input">
                            <input v-model.trim="password1" type="password" placeholder="비밀번호 " />
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">비밀번호 확인</p>
                        <p class="input">
                            <input
                                id="password2"
                                v-model.trim="password2"
                                type="password"
                                placeholder="비밀번호 확인"
                            />
                        </p>
                    </div>
                </div>

                <div class="form_item">
                    <div class="form_write">
                        <p class="title">업체</p>
                        <p class="input">
                            <select v-model="company" class="search_option">
                                <option :value="null">
                                    선택
                                </option>
                                <option v-for="(item, index) in companyList" :key="index" :value="item">
                                    {{ item.name }}
                                </option>
                            </select>
                        </p>
                    </div>
                </div>

                <div v-if="type === 'MANAGER'" class="form_item">
                    <div class="form_write">
                        <p class="title">직위</p>
                        <p class="input">
                            <select v-model="param.positionCode" class="search_option">
                                <option :value="null">
                                    선택
                                </option>
                                <option v-for="(item, index) in getPosition" :key="index" :value="item.code">
                                    {{ item.name }}
                                </option>
                            </select>
                        </p>
                    </div>
                </div>

                <div v-if="type === 'DRIVER'" class="form_item">
                    <div class="form_write">
                        <p class="title">장비 종류</p>
                        <p class="input">
                            <select v-model="param.driverType" class="search_option">
                                <option :value="null">
                                    선택
                                </option>
                                <option v-for="(item, index) in equipList" :key="index" :value="item.type">
                                    {{ item.name }}
                                </option>
                            </select>
                        </p>
                    </div>
                </div>
                <div v-if="type === 'DRIVER'" class="form_item">
                    <div class="form_write">
                        <p class="title">차량 번호 (장비)</p>
                        <p class="input">
                            <input v-model.trim="param.carNo" type="text" placeholder="30자 이내로 작성하세요." />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup_footer">
            <div class="foot_btns">
                <button type="button" class="cancel" @click="close">취소</button>
                <button type="button" class="comfirm" @click="save">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
import InfraApi from '../../api/coco/InfraApi';
import UserApi from '../../api/coco/UserApi';
import { mapGetters } from 'vuex';

export default {
    name: 'PopupModifyMember',
    components: {},
    props: {
        isShowCreate: {
            type: Boolean
        },
        companyList: {}
    },
    data: () => {
        return {
            company: null,
            param: {
                name: '',
                hp: '',
                siteId: null,
                passwd: null,
                companyId: null,
                companyName: null,
                positionCode: null,
                driverType: null,
                carNo: null,
                authType: null
            },
            password1: '',
            password2: '',
            equipList: [],
            type: 'MANAGER'
        };
    },
    computed: {
        ...mapGetters(['getPosition'])
    },
    watch: {
        type: function() {
            this.init();
        }
    },
    created() {},
    mounted() {
        const siteId = localStorage.getItem('siteId');
        this.param.siteId = siteId;
        InfraApi.equipList(siteId).then(res => {
            this.equipList = res.content;
        });
    },
    beforeDestroy() {},
    methods: {
        init() {
            this.param.name = '';
            this.param.hp = '';
            this.param.passwd = null;
            this.param.companyId = null;
            this.param.companyName = null;
            this.param.positionCode = null;
            this.param.driverType = null;
            this.param.carNo = null;
            this.company = null;
            this.password1 = '';
            this.password2 = '';
        },
        close() {
            this.init();
            this.$emit('popupCallback');
        },
        save() {
            if (!this.param.name) {
                this.$toast.warning('성명을 입력 해주세요.');
                return;
            }
            if (this.param.hp.indexOf('-') > -1 || this.param.hp.length < 11) {
                this.$toast.warning('핸드폰 번호를 확인 해주세요.');
                return;
            }

            if (this.password1 !== this.password2 || this.password1.length < 4) {
                this.$toast.warning('비밀번호를 확인 해주세요.');
                return;
            }

            if (this.company === null) {
                this.$toast.warning('업체를 선택 해주세요.');
                return;
            }

            this.param.companyId = this.company.id;
            this.param.companyName = this.company.name;

            if (this.type === 'MANAGER' && this.company.name === '대우건설') {
                this.param.authType = 'ADMIN';
            } else if (this.type === 'MANAGER') {
                this.param.authType = 'MANAGER';
            } else this.param.authType = 'WORKER';

            this.param.passwd = this.password1;

            UserApi.joinMember(this.param).then(() => {
                this.$toast.success('저장 되었습니다.');
                this.$emit('popupCallback');
                this.init();
            });
        }
    }
};
</script>
