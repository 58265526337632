<template>
    <div :style="{ display: isShow ? 'block' : 'none' }" class="popup modify_becon">
        <div class="popup_header">
            <div class="header_title">비콘 등록/수정</div>
            <button class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
            </button>
        </div>
        <div class="popup_content">
            <div class="form_wrap">
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">MAC Address</p>
                        <p class="input"><input v-model="param.uuid" type="text" /></p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">차량번호</p>
                        <p class="input"><input v-model="param.carNo" type="text" placeholder="ex. 서울 12가1234" /></p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">중장비 유형</p>
                        <p class="input">
                            <select v-model="param.equipType">
                                <option value="DUMP">덤프트럭</option>
                                <option value="ROLLER">롤러</option>
                                <option value="FORK_CRANE">굴착기</option>
                                <option value="FORK_LIFT">지게차</option>
                                <option value="BULLDOZER">불도저</option>
                                <option value="TOWER_CRANE">타워크레인</option>
                                <option value="BOAT">선박</option>
                                <option value="BARGE">바지선</option>
                                <option value="ETC">기타장비</option>
                            </select>
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">활성화 여부</p>
                        <p class="input">
                            <label class="custom_radio">
                                <input v-model="param.isUse" type="radio" name="modi_ch_01" value="true" />
                                <span>활성화</span>
                            </label>
                            <label class="custom_radio">
                                <input v-model="param.isUse" type="radio" name="modi_ch_01" value="false" />
                                <span>비활성화</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">장비정보</p>
                        <p class="input">
                            <textarea v-model="param.content" placeholder="내용을 입력하세요" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup_footer">
            <div class="foot_btns">
                <button type="button" class="cancel" @click="close">취소</button>
                <button type="button" class="comfirm" @click="save">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
import BleDeviceApi from '../../api/osm/BleDeviceApi';
export default {
    name: 'PopupModifyBeacon',
    components: {},
    props: {
        isShow: {
            type: Boolean
        },
        detailItem: {
            type: Object,
            default: null
        }
    },
    data: () => {
        return {
            param: {
                battery: 100,
                carNo: '',
                content: '',
                equipType: 'DUMP',
                isUse: true,
                uuid: ''
            }
        };
    },
    computed: {},
    watch: {
        detailItem: function() {
            this.param = this.detailItem;
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        paramInit() {
            this.param.battery = '';
            this.param.carNo = '';
            this.param.content = '';
            this.param.equipType = 'DUMP';
            this.param.isUse = true;
            this.param.uuid = '';
            this.param.id = null;
        },
        close() {
            this.paramInit();
            this.$emit('popupCallback');
        },
        save() {
            if (!this.param.uuid) {
                this.$toast.warning('MAC Address를 입력 해 주세요.');
                return false;
            }
            if (!this.param.carNo) {
                this.$toast.warning('차량번호를 입력 해 주세요.');
                return false;
            }
            if (!this.param.isUse) {
                this.$toast.warning('활성화여부를 선택 해 주세요.');
                return false;
            }

            if (this.param.id) {
                BleDeviceApi.update(this.param.id, this.param).then(() => {
                    this.$toast.success('저장 되었습니다.');
                    this.$emit('popupCallback');
                });
            } else {
                BleDeviceApi.save(this.param).then(() => {
                    this.paramInit();
                    this.$toast.success('저장 되었습니다.');
                    this.$emit('popupCallback');
                });
            }
        }
    }
};
</script>
