<template>
    <div :style="{ display: tabId === 2 ? 'block' : 'none' }" class="contain_wrap_tab_02">
        <div v-for="(item, index) in filterList" :key="index" class="name_card" data-rquip="dumpt">
            <div :class="getMemberStatus(item)" class="name_card_vi">{{ item.name.charAt(0) }}</div>
            <div class="name_card_info">
                <p class="name_card_name">{{ DRIVER_TYPE[item.driverType] }} / {{ item.name | mskName }}</p>
                <p class="name_card_comp">{{ item.carNo }}</p>
                <p class="name_card_time">{{ item.uuid }}</p>
            </div>
            <div class="name_card_checkin">
                <p :class="item.checkInTime ? 'on' : ''" class="check_in">
                    <span class="badge">출근</span> {{ item.checkInTime ? item.checkInTime : '-' }}
                </p>
                <p :class="item.checkOutTime ? 'on' : ''" class="check_out">
                    <span class="badge">퇴근</span>{{ item.checkOutTime ? item.checkOutTime : '-' }}
                </p>
                <!-- <p class="battery"><span :class="getBatteryCls(item.battery)" class="charge" />{{ item.battery }}%</p> -->
            </div>
        </div>
    </div>
</template>
<script>
import { DRIVER_TYPE } from '../../../../const';
import { mapState } from 'vuex';
import { getMemberStatus, getBatteryCls } from '../../../../utils/objectUtil';
export default {
    name: 'DumpTruckContent',
    components: {},
    props: {
        tabId: {
            type: Number,
            default: 1
        },
        searchText: {
            type: String,
            default: ''
        },
        isBounds: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            filterList: [],
            DRIVER_TYPE: {}
        };
    },
    computed: {
        ...mapState({
            carList: state => state.common.carList,
            mapBounds: state => state.common.mapBounds
        })
    },
    watch: {
        searchText: function() {
            if (this.searchText.length >= 1) {
                this.filterList = this.carList.filter(
                    item => item.name.indexOf(this.searchText) > -1 || item.company.indexOf(this.searchText) > -1
                );
            } else {
                if (this.carList.length !== this.filterList) {
                    this.filterList = this.carList;
                }
            }
        },
        carList: function() {
            this.setFilterDump();
        },
        mapBounds: function() {
            this.setFilterBound();
        },
        isBounds: function() {
            if (this.isBounds) {
                this.setFilterBound();
            } else {
                this.setFilterDump();
            }
        }
    },
    created() {},
    mounted() {
        this.DRIVER_TYPE = DRIVER_TYPE;
    },
    beforeDestroy() {},
    methods: {
        getMemberStatus: getMemberStatus,
        getBatteryCls: getBatteryCls,
        setFilterBound() {
            if (this.isBounds) {
                if (this.mapBounds._ne) {
                    this.setFilterDump();
                    this.filterList = this.filterList.filter(item => {
                        return (
                            item.lng <= this.mapBounds._ne.lng &&
                            item.lat <= this.mapBounds._ne.lat &&
                            item.lng >= this.mapBounds._sw.lng &&
                            item.lat >= this.mapBounds._sw.lat
                        );
                    });
                }
            }
        },
        setFilterDump() {
            this.filterList = this.carList.filter(item => item.driverType === 'DUMP');
        }
    }
};
</script>
