import { httpCoCoLoginClient, cocoHttpClient } from '../../network/httpclient/HttpClientFactory';
import router from '../../router';

const login = (param, headerParam) =>
    httpCoCoLoginClient.post('/user/login', param, { headers: headerParam }).then(res => res.data);
const retoken = headerParam =>
    httpCoCoLoginClient
        .post('/user/token/refresh', { memberId: headerParam.memberId }, { headers: headerParam })
        .then(res => res.data);
const logout = () => {
    router.push({ name: 'Login' });
    localStorage.clear();
};
const joinMember = param => cocoHttpClient.post('/user/osm/join', param).then(res => res.data);

export default {
    login,
    retoken,
    logout,
    joinMember
};
