import { httpClient } from '../../network/httpclient/HttpClientFactory';

const getAccident = () => httpClient.get(`/site/accident-count`).then(res => res.data);
const getProgress = () => httpClient.get(`/site/progress`).then(res => res.data);
const detail = () => httpClient.get(`/site`).then(res => res.data);
const save = param => httpClient.put(`/site`, param).then(res => res.data);
export default {
    getAccident,
    getProgress,
    detail,
    save
};
