const imgPath = process.env.IMG_PATH;

export const workNoImage = url => {
    if (!url) return imgPath + 'img/common/work_default.png';
    return url;
};

export const getImgPath = () => {
    return imgPath;
};
