<template>
    <body>
        <layout-header />

        <div class="container" :class="menuView">
            <!--menu_open-->
            <left-menu
                :menu-id="menuId"
                @menuViewChange="menuViewChange"
                @onLogout="onLogout"
                @openWorkView="openWorkView"
                @openNotice="openNotice"
            />
            <div class="contents">
                <!-- 범례 추가 -->
                <div v-if="siteId === '2'" class="bottom_wrap" style="z-index: 1;">
                    <div class="people_wrap left">
                        <div class="people_inner">
                            <div class="people_sec sec_03">
                                <div class="poeple_title">대우건설</div>
                                <div class="poeple_card">
                                    <div class="card_item card_p">{{ memberComapnyCount['대우건설'].worker }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="people_wrap">
                        <div class="people_inner">
                            <div class="people_sec sec_01">
                                <div class="poeple_title">토성건설(주)</div>
                                <div class="poeple_card">
                                    <div class="card_item card_p">{{ memberComapnyCount['토성토건(주)'].worker }}</div>
                                    <div class="card_item card_t">{{ memberComapnyCount['토성토건(주)'].car }}</div>
                                    <div class="card_item card_s">{{ memberComapnyCount['토성토건(주)'].boat }}</div>
                                </div>
                            </div>
                            <div class="people_sec sec_02">
                                <div class="poeple_title">(주)큐텍이씨에스</div>
                                <div class="poeple_card">
                                    <div class="card_item card_p">
                                        {{ memberComapnyCount['(주)큐텍이씨에스'].worker }}
                                    </div>
                                    <div class="card_item card_t">{{ memberComapnyCount['(주)큐텍이씨에스'].car }}</div>
                                    <div class="card_item card_s">
                                        {{ memberComapnyCount['(주)큐텍이씨에스'].boat }}
                                    </div>
                                </div>
                            </div>
                            <div class="people_sec sec_total">
                                <div class="poeple_title">총 투입 현황</div>
                                <div class="poeple_card">
                                    <div class="card_item card_p">
                                        {{
                                            memberComapnyCount['토성토건(주)'].worker +
                                                memberComapnyCount['(주)큐텍이씨에스'].worker +
                                                memberComapnyCount['대우건설'].worker
                                        }}
                                    </div>
                                    <div class="card_item card_t">
                                        {{
                                            memberComapnyCount['토성토건(주)'].car +
                                                memberComapnyCount['(주)큐텍이씨에스'].car
                                        }}
                                    </div>
                                    <div class="card_item card_s">
                                        {{
                                            memberComapnyCount['토성토건(주)'].boat +
                                                memberComapnyCount['(주)큐텍이씨에스'].boat
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 범례 추가 -->
                <div id="map" class="map_wrap" />
                <choice-map-style @changeMapStyle="changeMapStyle" @moveBookmarks="moveBookmarks" />
                <contents-btns
                    :contents-btns-value="contentsBtnsValue"
                    @changeContentsBtnsValue="changeContentsBtnsValue"
                />
            </div>
        </div>

        <popup-dash-admin v-if="menuId === 3" :current-popup="currentPopup" @setMapCenter="setMapCenter" />
        <popup-commute-admin v-if="menuId === 8" :current-popup="currentPopup" />
        <popup-cctv />
        <popup-calendar v-if="menuId === 5" :current-popup="currentPopup" />
        <popup-work-calendar v-if="menuId === 7" :current-popup="currentPopup" />
        <popup-inspection />
        <popup-logout />
        <!-- <popup-work-view @closeWorkView="closeWorkView" /> -->
        <popup-work-view
            :work-view-is-show="workViewIsShow"
            :work-view-item="workViewItem"
            :work-action-item="workActionItem"
            @closeWorkView="closeWorkView"
        />
        <popup-modify-notice
            :detail-item="noticeDetailItem"
            :is-show="noticeIsShow"
            @popupCallback="popupNoticeCallback"
        />
        <confirm :param="confirmParam" @confirmCallback="confirmCallback" />
    </body>
</template>
<script>
import LayoutHeader from '../layout/LayoutHeader.vue';
import LeftMenu from '../layout/LeftMenu.vue';
import ContentsBtns from '../layout/mapObject/ContentsBtns.vue';

import PopupLogout from '../popup/PopupLogout.vue';
import PopupInspection from '../popup/PopupInspection.vue';
import PopupCalendar from '../popup/PopupCalendar.vue';
import PopupCctv from '../popup/PopupCctv.vue';

import CustomObjectApi from '../../api/osm/CustomObjectApi';
import UserApi from '../../api/coco/UserApi';
import SiteCommonApi from '../../api/osm/SiteCommonApi';
import PopupDashAdmin from '../popup/PopupDashAdmin.vue';
import InfraApi from '../../api/coco/InfraApi';
import PopupCommuteAdmin from '../popup/PopupCommuteAdmin.vue';

import { POPUP_NAME } from '../../const';
import Confirm from '../popup/Confirm.vue';
import PopupWorkCalendar from '../popup/PopupWorkCalendar.vue';
import PopupWorkView from '../popup/PopupWorkView.vue';
import PopupModifyNotice from '../popup/PopupModifyNotice.vue';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_ACCESS_TOKEN, MAPBOX_MAP_STYLE, MAPBOX_MAP_STYLE_SATELLITE, DEV_LEVEL, SITE_ID } from '../../const';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import { Threebox } from 'threebox-plugin';

import WorkApi from '../../api/coco/WorkApi';
import { bindFirstImageThumb } from '../../helper/ImageHelper';
import CommuteApi from '../../api/coco/CommuteApi';
import { distinctById } from '../../utils/objectUtil';

import { format } from 'date-fns';
import { mapState, mapActions } from 'vuex';
import ChoiceMapStyle from '../layout/mapObject/ChoiceMapStyle.vue';
import {
    cstrLine,
    officePolygon,
    bridgePanel,
    tunnelPanel,
    setGisInfo,
    generateDriverTypeIconElement,
    generate3dIconElement
} from '../../helper/MapObjectHelper';
export default {
    name: 'Main',
    components: {
        LayoutHeader,
        LeftMenu,
        PopupLogout,
        PopupInspection,
        PopupCalendar,
        PopupCctv,
        ContentsBtns,
        PopupDashAdmin,
        Confirm,
        PopupWorkCalendar,
        PopupWorkView,
        ChoiceMapStyle,
        PopupCommuteAdmin,
        PopupModifyNotice
    },
    data: () => {
        return {
            accessToken: MAPBOX_ACCESS_TOKEN,
            mapStyle: MAPBOX_MAP_STYLE,
            mapStyleSatellite: MAPBOX_MAP_STYLE_SATELLITE,
            map: null,
            site: { name: '' },
            siteId: localStorage.getItem(SITE_ID),
            menuView: 'menu_open',
            currentPopup: '',
            confirmParam: {
                title: '',
                msg: '',
                isShow: false
            },
            workViewItem: null,
            workActionItem: null,
            workViewIsShow: false,
            noticeIsShow: false,
            noticeDetailItem: null,
            menuId: 1,
            mapInitInfo: {},
            markerMap: {},
            contentsBtnsValue: {
                worker: 'on',
                coco: 'on',
                equip: 'on',
                cctv: 'on'
            },
            lastMenuId: 4,
            intervals: [],
            companyList: [],
            devLevel: localStorage.getItem(DEV_LEVEL) ? localStorage.getItem(DEV_LEVEL) : 0,

            /*
                0:All
                1:GIS X / INTERVAL O
                2:GIS X / INTERVAL X
            */
            memberList: [{ name: '진기준', company: '대우건설', carNo: '01나2022' }], //TODO전체 작업자 목록
            memberComapnyCount: {
                '토성토건(주)': {
                    worker: 0,
                    car: 0,
                    boat: 0
                },
                '(주)큐텍이씨에스': {
                    worker: 0,
                    car: 0,
                    boat: 0
                },
                대우건설: {
                    worker: 0,
                    car: 0,
                    boat: 0
                }
            } //TODO업체별 카운트 목록
        };
    },
    computed: {
        ...mapState({
            workList: state => state.common.workList,
            workerList: state => state.common.workerList,
            carList: state => state.common.carList
        })
    },
    watch: {
        workList: function() {
            this.getDashboardWork();
        }
    },
    created() {},
    async mounted() {
        this.clearInterval();
        document.title = 'OSM::On Site Monitoring';
        this.site = await SiteCommonApi.detail();
        this.mapInitInfo = JSON.parse(this.site.mapInitInfo);
        await this.getCommute();
        this.companyList = await InfraApi.companyList();
        this.commuteInterval();
        this.getWorkList();
        this.workInterval();
        this.refreshInterval();
        this.mapinit(this.mapStyleSatellite);
    },
    beforeDestroy() {},
    methods: {
        clearInterval() {
            for (var i = 0; i < 100; i++) {
                clearInterval(i);
            }
        },
        commuteInterval() {
            if (this.devLevel < 2) this.intervals.push(setInterval(this.getCommute, 1000 * 30)); //재조회 1분
        },
        workInterval() {
            if (this.devLevel < 2) this.intervals.push(setInterval(this.getWorkList, 1000 * 30)); //재조회 1분
        },
        dashboardInterval() {
            if (this.devLevel < 2) {
                this.intervals.push(
                    setInterval(() => {
                        this.getDashboard();
                    }, 1000 * 30)
                );
            }
        },
        refreshInterval() {
            if (this.devLevel < 2) {
                this.intervals.push(
                    setInterval(() => {
                        this.$router.go(this.$router.currentRoute);
                    }, 1000 * 60 * 60)
                );
            }
        },
        onLogout() {
            this.confirmParam.title = '로그아웃';
            this.confirmParam.msg = '로그아웃 하시겠습니까?';
            this.confirmParam.isShow = true;
        },
        confirmCallback() {
            this.intervals.map(id => clearInterval(id));

            UserApi.logout();
        },
        closeWorkView() {
            this.workActionItem = null;
            this.workViewIsShow = false;
        },
        openWorkView(item) {
            // this.workViewIsShow = true;
            // this.workViewItem = item;
            WorkApi.workAction(item.id).then(res => {
                this.workActionItem = res.content;
                this.workViewIsShow = true;
                this.workViewItem = item;
            });
        },
        openNotice(item) {
            this.noticeIsShow = true;
            item.isReadOnly = true;
            this.noticeDetailItem = item;
        },
        popupNoticeCallback() {
            this.noticeIsShow = false;
        },
        ...mapActions(['setWorkerList', 'setCarList', 'setMapBounds', 'setWorkList']),
        /**
         * Redis에 정리된 사용자의 마지막 위치정보 조회
         */
        async getDashboard() {
            const points = await CustomObjectApi.dashboard();
            if (points) {
                points.forEach(value => {
                    const el = generateDriverTypeIconElement(value, this.workerList, this.carList, this.companyList);

                    if (el) {
                        const key = `${value.driverType !== null ? 'EQUIP' : 'WORKER'}-${value.id.memberId}`;
                        if (this.markerMap[key]) {
                            this.markerMap[key].removeLabel();
                        }

                        if (key.includes('WORKER') && this.contentsBtnsValue.worker !== 'on') {
                            return;
                        }

                        if (key.includes('EQUIP') && this.contentsBtnsValue.equip !== 'on') {
                            return;
                        }

                        this.markerMap[key] = this.addLabel([value.lng, value.lat], el);
                    }
                });
            }
            if (this.siteId === '2') {
                new mapboxgl.Marker(generate3dIconElement('a'))
                    .setLngLat([126.31522640151309, 34.79012200212962])
                    .setPitchAlignment('map')
                    .addTo(this.map);

                new mapboxgl.Marker(generate3dIconElement('b'))
                    .setLngLat([126.32905996829146, 34.81714314918486])
                    .setPitchAlignment('map')
                    .addTo(this.map);

                new mapboxgl.Marker(generate3dIconElement('c'))
                    .setLngLat([126.2265121701526, 34.84912753408052])
                    .setPitchAlignment('map')
                    .addTo(this.map);
            }
        },
        addLabel(lnglat, el) {
            var label = window.tb.label({
                position: lnglat,
                htmlElement: el,
                alwaysVisible: true,
                bottomMargin: 0,
                feature: null
            });
            label.setCoords(lnglat);
            window.tb.add(label);

            return label;
        },
        getDashboardWork() {
            if (this.workList && window.tb) {
                this.workList.forEach(value => {
                    const key = `COCO-${value.id}`;
                    const el = document.createElement('p');
                    const imgEl = document.createElement('img');
                    el.className = 'marker marker type_coco';
                    imgEl.className = 'coco';
                    imgEl.dataset.id = value.id;
                    // el.dataset.id = value.id;

                    imgEl.style = 'top:12rem;right:19.4rem';
                    imgEl.src = '../img/common/map/pin_coco.svg';
                    el.append(imgEl);
                    el.addEventListener('click', () => {
                        this.openWorkView(this.workList.find(work => work.id === value.id));
                    });

                    if (this.markerMap[key]) {
                        this.markerMap[key].removeLabel();
                    }
                    if (this.contentsBtnsValue.coco === 'on')
                        this.markerMap[key] = this.addLabel([value.longitude, value.latitude], el);
                });
            }
        },
        async getCommute() {
            const todayList = await CommuteApi.today();

            let workerList = [];
            if (todayList.content.worker) workerList = distinctById(JSON.parse(todayList.content.worker));
            let carList = [];
            if (todayList.content.car) carList = distinctById(JSON.parse(todayList.content.car));

            const lastPointReq = [];
            let lastPoints = [];
            this.commuteListInit(workerList, lastPointReq);
            this.commuteListInit(carList, lastPointReq);

            if (lastPointReq.length > 0) lastPoints = await CustomObjectApi.lastPoints(lastPointReq);
            if (workerList) {
                workerList.map(item => {
                    const point = lastPoints.find(point => {
                        return point.memberId === item.id && point.siteId === item.siteId;
                    });
                    if (point) {
                        item.isMissing = point.isMissing;
                        item.lastTimestamp = point.createdAt;
                        if (point.createdAt) {
                            item.lastDateTime = format(new Date(point.createdAt), 'yyyy-mm-dd HH:mm:ss');
                        } else {
                            item.lastDateTime = '이력없음';
                        }
                        item.lat = point.lat;

                        item.lng = point.lng;
                    }
                });
                this.setWorkerList(workerList);
            } else {
                this.setWorkerList([]);
            }

            if (carList) {
                carList.map(item => {
                    const point = lastPoints.find(point => {
                        return point.memberId === item.id && point.siteId === item.siteId;
                    });
                    if (point) {
                        item.isMissing = point.isMissing;
                        item.lastTimestamp = point.createdAt;
                        if (point.createdAt) {
                            item.lastDateTime = format(new Date(point.createdAt), 'yyyy-mm-dd HH:mm:ss');
                        } else {
                            item.lastDateTime = '이력없음';
                        }
                        item.lat = point.lat;

                        item.lng = point.lng;
                    }
                });
                this.setCarList(carList);
            } else {
                this.setCarList([]);
            }

            Object.keys(this.memberComapnyCount).map(company => {
                this.memberComapnyCount[company] = { worker: 0, car: 0, boat: 0 };
            });

            this.memberList = workerList.concat(carList);
            this.memberList.map(member => {
                if (!this.memberComapnyCount[member.company]) {
                    this.memberComapnyCount[member.company] = { worker: 0, car: 0, boat: 0 };
                }
                switch (member.driverType) {
                    case null:
                    case undefined:
                        this.memberComapnyCount[member.company].worker =
                            this.memberComapnyCount[member.company].worker + 1;
                        break;
                    case 'BARGE':
                    case 'BOAT':
                        this.memberComapnyCount[member.company].boat = this.memberComapnyCount[member.company].boat + 1;
                        break;
                    default:
                        this.memberComapnyCount[member.company].car = this.memberComapnyCount[member.company].car + 1;
                        break;
                }
            });
        },
        commuteListInit(list, lastPointReq) {
            list.map(item => {
                item.checkInTime = '';
                item.checkOutTime = '';
                item.totalHour = '';
                item.lastTimestamp = '';
                if (item.id && item.siteId) {
                    lastPointReq.push({
                        memberId: item.id,
                        siteId: item.siteId
                    });
                }
            });
        },
        mapinit(style) {
            if (!style) {
                style = this.mapStyle;
            }
            mapboxgl.accessToken = this.accessToken;
            this.map = new mapboxgl.Map({
                container: 'map', // container ID
                style: style, // style URL
                center: this.mapInitInfo.center,
                // center: [126.74233538655949, 34.71839126770078],
                // zoom: 13.5,
                zoom: this.mapInitInfo.zoom,
                pitch: this.mapInitInfo.pitch,
                bearing: this.mapInitInfo.bearing
            });

            this.map.on('load', () => {
                this.dashboardInterval();

                this.setMapBounds(this.map.getBounds());

                this.makePanel();

                if (this.devLevel < 1) CustomObjectApi.mapGisinfo().then(res => setGisInfo(this.map, res));
                // dem 고도적용된 맵
                // this.map.addSource('mapbox-dem', {
                //     type: 'raster-dem',
                //     url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                //     tileSize: 512,
                //     maxzoom: 14
                // });
                // this.map.setTerrain({ source: 'mapbox-dem', exaggeration: 1 });
            });
            this.map.on('style.load', () => {
                window.tb = new Threebox(this.map, this.map.getCanvas().getContext('webgl'), {
                    defaultLights: true
                });
                this.model3d();
                this.getDashboardWork();
                this.getDashboard();

                // this.map.addSource('maine', {
                //     type: 'geojson',
                //     data: {
                //         type: 'Feature',
                //         geometry: {
                //             type: 'Polygon',
                //             // These coordinates outline Maine.
                //             coordinates: [
                //                 [
                //                     [126.74489726607163, 34.68617181460196],
                //                     [126.74489726607163, 34.7493789946832],
                //                     [126.75650718239933, 34.7493789946832],
                //                     [126.75650718239933, 34.68617181460196],
                //                     [126.74489726607163, 34.68617181460196]
                //                 ]
                //             ]
                //         }
                //     }
                // });

                // // Add a new layer to visualize the polygon.
                // this.map.addLayer({
                //     id: 'maine',
                //     type: 'fill',
                //     source: 'maine', // reference the data source
                //     layout: {},
                //     paint: {
                //         'fill-color': '#0080ff', // blue color fill
                //         'fill-opacity': 0.5
                //     }
                // });
                // // Add a black outline around the polygon.
                // this.map.addLayer({
                //     id: 'outline',
                //     type: 'line',
                //     source: 'maine',
                //     layout: {},
                //     paint: {
                //         'line-color': '#000',
                //         'line-width': 3
                //     }
                // });
            });

            this.map.on('moveend', () => {
                this.setMapBounds(this.map.getBounds());
            });

            this.map.on('click', e => {
                console.log(`A click event has occurred at ${e.lngLat}`);

                if (!(e.originalEvent.target instanceof HTMLElement)) return;
                if (e.originalEvent.target.className.includes('coco')) {
                    this.openWorkView(
                        this.workList.find(work => work.id === Number(e.originalEvent.target.dataset.id))
                    );
                }
            });

            if (this.devLevel > 2) {
                // MapboxDraw 폴리곤 그리기 / 속성 가져오기
                const draw = new MapboxDraw({
                    displayControlsDefault: false,
                    // Select which mapbox-gl-draw control buttons to add to the map.
                    controls: {
                        polygon: true,
                        trash: true
                    },
                    // Set mapbox-gl-draw to draw by default.
                    // The user does not have to click the polygon control button first.
                    defaultMode: 'draw_polygon'
                });
                this.map.addControl(draw);

                this.map.on('draw.create', this.updateArea);
                this.map.on('draw.delete', this.updateArea);
                this.map.on('draw.update', this.updateArea);
            }
        },
        /**
         * 보고있는 지도 중심을 해당 위치로 이동
         * @param {Array} coords [lng, lat]
         */
        setMapCenter(coords) {
            this.menuViewChange(1);
            if (this.map) {
                const centerMarker = new mapboxgl.Marker().setLngLat(coords).addTo(this.map);
                this.$toast.success('해당 위치로 이동 했습니다');
                setTimeout(() => {
                    centerMarker.remove();
                }, 5000);
                this.map.setCenter(coords);
            }
        },
        changeMapStyle(type) {
            window.tb.dispose();
            this.map.remove();
            this.markerMap = {};

            if (type === 'default') {
                this.mapinit();
            } else {
                this.mapinit(this.mapStyleSatellite);
            }

            this.contentsBtnsValue.worker = 'on';
            this.contentsBtnsValue.coco = 'on';
            this.contentsBtnsValue.equip = 'on';
            this.contentsBtnsValue.cctv = 'on';
        },
        async moveBookmarks(item) {
            if (!item) {
                this.map.setCenter(this.mapInitInfo.center);
                this.map.setZoom(this.mapInitInfo.zoom);
                return false;
            }

            if (item.zoomLevel) this.map.setZoom(item.zoomLevel);
            this.map.setCenter([item.lng, item.lat]);
            this.map.setPitch(item.pitch);
            this.map.setBearing(item.bearing);
            this.$toast.success(`${item.name}로 이동 했습니다`);
        },
        updateArea(e) {
            console.log(e);
            // const points = e.features[0].geometry.coordinates[0].map(coordinate => {
            //     return { lng: coordinate[0], lat: coordinate[1] };
            // });
            // console.log(points);
            // CustomObjectApi.workPlace({ points });
        },
        changeContentsBtnsValue(type) {
            if (this.contentsBtnsValue[type] !== 'on') {
                this.contentsBtnsValue[type] = 'on';
            } else {
                this.contentsBtnsValue[type] = '';
            }
            this.changeMarker(type);
        },
        changeMarker(type) {
            for (var key in this.markerMap) {
                switch (type) {
                    case 'worker':
                        if (key.includes('WORKER')) {
                            this.findTypeAndDisplay(type, key);
                        }
                        break;
                    case 'equip':
                        if (key.includes('EQUIP')) {
                            this.findTypeAndDisplay(type, key);
                        }
                        break;
                    case 'coco':
                        if (key.includes('COCO')) {
                            this.findTypeAndDisplay(type, key);
                        }
                        break;
                    case 'cctv':
                        if (key.includes('cctv')) {
                            this.findTypeAndDisplay(type, key);
                        }
                        break;
                }
            }
        },
        findTypeAndDisplay(type, key) {
            this.markerMap[key].removeLabel();
            if (this.contentsBtnsValue[type] === 'on') {
                const label = this.markerMap[key].userData;
                delete this.markerMap[key];
                this.markerMap[key] = this.addLabel(label.position, label.htmlElement);
            }
        },
        refreshContentBtn() {
            this.changeMarker('worker');
            this.changeMarker('equip');
            this.changeMarker('coco');
            this.changeMarker('cctv');
        },
        getWorkList() {
            let workList = [];
            WorkApi.list(20).then(async result => {
                const prevIds = this.workList.map(work => work.id);
                workList = result.content.map(async item => {
                    item.imageUrl = null;

                    if (prevIds.find(id => id === item.id)) {
                        item.imageUrl = this.workList.find(work => work.id === item.id).imageUrl;
                    } else {
                        if (item.imageFileId) {
                            item.imageUrl = await bindFirstImageThumb(item.imageFileId, '130X130');
                        }
                    }

                    return Object.assign({}, item);
                });

                const resultList = await Promise.all(workList);
                this.setWorkList(resultList);
            });
        },
        async makePanel() {
            const bridgeList = [];
            const tunnelList = [];

            const panelList = await CustomObjectApi.mapPenel();

            panelList.map(item => {
                item.lnglat = [item.lng, item.lat];
                if (item.panelType === 'BRIDGE') {
                    bridgeList.push(item);
                    return false;
                }
                if (item.panelType === 'TUNNEL') {
                    tunnelList.push(item);
                    return false;
                }
            });

            bridgeList.map(res => {
                bridgePanel(res, this.map, mapboxgl);
            });

            tunnelList.map(res => {
                tunnelPanel(res, this.map, mapboxgl);
            });

            const lineList = await CustomObjectApi.mapLine();
            officePolygon(this.site, this.map, mapboxgl);
            cstrLine(lineList, this.site, this.map, mapboxgl);
        },
        model3d() {
            this.map.addLayer({
                id: 'custom_layer',
                type: 'custom',
                renderingMode: '3d',
                onAdd: async () => {
                    const models = await CustomObjectApi.mapThreeModel();

                    models.map(item => {
                        window.tb.loadObj(
                            {
                                type: 'gltf',
                                obj: item.objSrc,
                                units: 'meters',
                                scale: item.scale,
                                rotation: JSON.parse(item.rotation),
                                anchor: 'center'
                            },
                            model => {
                                model.setCoords([item.lng, item.lat]);
                                window.tb.add(model);
                            }
                        );
                    });
                },
                render: () => window.tb.update()
            });
        },

        /**
         * 좌측 메뉴에서 선택한 menuId를 받아 html class 설정
         * @param {number} menuId 활성화 Left 메뉴
         * @returns return null
         */
        menuViewChange(menuId) {
            this.currentPopup = '';
            this.menuId = menuId;

            switch (menuId) {
                case 0:
                    if (this.menuView === '') {
                        this.menuView = 'menu_open';
                        this.menuId = this.lastMenuId;
                    } else {
                        this.menuView = '';
                    }
                    setTimeout(() => {
                        this.map.resize();
                    }, 100);

                    break;
                case 1: //작업자
                    this.menuView = 'menu_open';
                    this.lastMenuId = menuId;
                    // this.contentsBtnsValue.worker = 'on';
                    // this.contentsBtnsValue.coco = '';
                    // this.contentsBtnsValue.equip = '';
                    // this.contentsBtnsValue.cctv = '';

                    this.refreshContentBtn();
                    break;
                case 2: //차량
                    this.menuView = 'menu_open';
                    this.lastMenuId = menuId;
                    // this.contentsBtnsValue.worker = '';
                    // this.contentsBtnsValue.coco = '';
                    // this.contentsBtnsValue.equip = 'on';
                    // this.contentsBtnsValue.cctv = '';

                    this.refreshContentBtn();
                    break;
                case 3:
                    this.menuView = '';
                    this.currentPopup = POPUP_NAME.ADMIN;
                    break;
                case 4: //현장공지
                    this.menuView = 'menu_open';
                    this.lastMenuId = menuId;
                    // this.contentsBtnsValue.worker = 'on';
                    // this.contentsBtnsValue.coco = 'on';
                    // this.contentsBtnsValue.equip = 'on';
                    // this.contentsBtnsValue.cctv = 'on';

                    this.refreshContentBtn();
                    break;
                case 5:
                    this.menuView = '';
                    this.currentPopup = POPUP_NAME.CALENDAR;
                    break;
                case 6: //CCTV
                    this.menuView = 'menu_open';
                    // this.contentsBtnsValue.worker = '';
                    // this.contentsBtnsValue.coco = '';
                    // this.contentsBtnsValue.equip = '';
                    // this.contentsBtnsValue.cctv = 'on';
                    alert('CCTV메뉴 준비중입니다.');
                    this.refreshContentBtn();
                    break;
                case 7:
                    this.menuView = '';
                    this.currentPopup = POPUP_NAME.WORK_CALENDAR;
                    break;
                case 8:
                    this.menuView = '';
                    this.currentPopup = POPUP_NAME.COMMUTE;
                    break;
                case 9:
                    this.menuView = '';
                    this.currentPopup = POPUP_NAME.COMMUTE;
                    break;
                default:
                    this.menuView = 'menu_open';
                    break;
            }
        }
    }
};
</script>
