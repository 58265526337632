<template>
    <div :style="{ display: currentPopup === POPUP_NAME.ADMIN ? 'block' : 'none' }" class="dash_admin_wrap">
        <div class="dash_admin_inner">
            <div class="dash_admin_head">
                <p class="title">대시보드 관리 페이지</p>
            </div>
            <div class="dash_admin_content">
                <div class="dash_admin_left">
                    <ul>
                        <li>
                            <button type="button" :class="tabId === 1 ? 'on' : ''" @click="tabId = 1">
                                현장 정보 관리
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 2 ? 'on' : ''" @click="tabId = 2">
                                비콘 정보 관리
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 3 ? 'on' : ''" @click="tabId = 3">
                                현장 공지 관리
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 4 ? 'on' : ''" @click="tabId = 4">
                                현장 일정 관리
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 5 ? 'on' : ''" @click="tabId = 5">
                                현장 업체 관리
                            </button>
                        </li>
                        <li>
                            <button type="button" :class="tabId === 6 ? 'on' : ''" @click="tabId = 6">
                                현장 직원 관리
                            </button>
                        </li>
                        <li>
                            <button type="button" @click="onBimProgressAdmin">
                                진척도 관리
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="dash_admin_cont">
                    <dash-admin-01 :tab-id="tabId" />
                    <dash-admin-02 :tab-id="tabId" @setMapCenter="setMapCenter" />
                    <dash-admin-03 :tab-id="tabId" />
                    <dash-admin-04 :tab-id="tabId" />
                    <dash-admin-05 :tab-id="tabId" />
                    <dash-admin-06 :tab-id="tabId" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DashAdmin01 from './DashAdmin/DashAdmin01.vue';
import DashAdmin02 from './DashAdmin/DashAdmin02.vue';
import DashAdmin03 from './DashAdmin/DashAdmin03.vue';
import DashAdmin04 from './DashAdmin/DashAdmin04.vue';
import DashAdmin05 from './DashAdmin/DashAdmin05.vue';
import DashAdmin06 from './DashAdmin/DashAdmin06.vue';
import { POPUP_NAME, APPLICATION_PHASE, JWT_TOKEN } from '../../const';
export default {
    name: 'PopupDashAdmin',
    components: { DashAdmin01, DashAdmin02, DashAdmin03, DashAdmin04, DashAdmin05, DashAdmin06 },
    props: {
        currentPopup: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            tabId: 1,
            POPUP_NAME: POPUP_NAME
        };
    },
    computed: {},
    created() {},
    mounted() {
        console.log(process.env.PROGRESS_DOMAIN);
    },
    beforeDestroy() {},
    methods: {
        setMapCenter(coords) {
            this.$emit('setMapCenter', coords);
        },
        onBimProgressAdmin() {
            var token = localStorage.getItem(JWT_TOKEN);
            var reToken = localStorage.getItem(JWT_REFRESH_TOKEN);

            const PROGRESS_DOMAIN = process.env.PROGRESS_DOMAIN;

            //서브 도메인간의 cookie 는 전달됩  그러나 개발환경에서 서브 도메인 환경을 맞추기 힘들기 때문에 일단 param으로 넘김
            if (APPLICATION_PHASE === 'REAL') {
                document.cookie =
                    'osm_token=' +
                    token +
                    ';osm_reToken=' +
                    reToken +
                    '; domain=.dscosm.com; path=/; SameSite=None; Secure';
            }

            const win = window.open(`${PROGRESS_DOMAIN}/#/sso/${token}`, '_blank');
            win.focus();
        }
    }
};
</script>
