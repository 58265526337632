import { cocoHttpClient } from '../../network/httpclient/HttpClientFactory';

const status = params => cocoHttpClient.get(`/work/osm/work-status`, { params }).then(res => res.data);
const list = size => cocoHttpClient.get(`/work/osm/${size}/work-list`).then(res => res.data);
const workAction = param => cocoHttpClient.get(`/work/action/work/${param}`).then(res => res.data);
export default {
    status,
    list,
    workAction
};
