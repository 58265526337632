<template>
    <div :style="{ display: tabId === 4 ? 'block' : 'none' }" class="dash_admin_box dash_commute_04">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">
                업체담당자에 의해 등록된 출근목록
            </p>
        </div>
        <!-- 검색 Wrap -->
        <div class="option_wrap">
            <div class="search_filter">
                <form>
                    <div class="option_date">
                        <date-picker
                            id="startedDate"
                            v-model="listParam.beginDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                        <span>~</span>
                        <date-picker
                            id="endedDate"
                            v-model="listParam.endDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                    </div>

                    <select v-show="authType === 'ADMIN'" v-model="listParam.companyId" class="search_option">
                        <option value="">
                            선택
                        </option>
                        <option v-for="(code, index) in companyList" :key="index" :value="code.id">
                            {{ code.name }}
                        </option>
                    </select>

                    <select v-show="authType === 'ADMIN'" v-model="listParam.driverType" class="search_option">
                        <option value="">
                            장비종류
                        </option>
                        <option v-for="(equip, index) in equipList" :key="index" :value="equip.type">
                            {{ equip.name }}
                        </option>
                    </select>

                    <div class="option_date w100">
                        <input
                            v-model="listParam.name"
                            type="text"
                            placeholder="성명 검색"
                            @keyup.enter="onClickSearch"
                        />
                    </div>

                    <div class="search_button">
                        <button type="button" @click="onClickSearch">
                            검색
                        </button>
                    </div>
                    <div class="search_button">
                        <button type="button" @click="confirm(memberIds)">
                            일지확정
                        </button>
                    </div>
                    <div class="search_button">
                        <button type="button" @click="cancel(memberIds)">
                            확정취소
                        </button>
                    </div>
                </form>
            </div>
            <div class="right_area">
                <div class="download_button">
                    <button type="button" @click="excel">
                        엑셀 다운로드
                    </button>
                </div>
            </div>
        </div>
        <!-- 검색 Wrap //-->

        <div class="dash_admin_box_table">
            <table>
                <thead>
                    <tr>
                        <th width="40">
                            <label class="custom_checkbox">
                                <input v-model="allSelected" type="checkbox" @click="selectAll" />
                            </label>
                        </th>
                        <th width="90">
                            순번
                        </th>
                        <th>
                            근무일
                        </th>
                        <th>
                            업체명
                        </th>
                        <th>
                            성명
                        </th>
                        <th>
                            차량종류
                        </th>
                        <th>
                            차량번호
                        </th>
                        <th>
                            출근시간
                        </th>
                        <th>
                            퇴근시간
                        </th>
                        <th>
                            근무형태
                        </th>
                        <th>
                            확정일자
                        </th>
                        <th>
                            담당자
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="pageParam.total === 0">
                        <td colspan="12">
                            검색 결과가 없습니다.
                        </td>
                    </tr>
                    <template v-if="pageParam.total !== 0">
                        <tr v-for="(item, index) in pageParam.content" :key="index">
                            <td>
                                <label class="custom_checkbox">
                                    <input v-model="memberIds" :value="item.id" type="checkbox" @click="select" />
                                </label>
                            </td>
                            <td>{{ pageParam.total - ((listParam.page - 1) * listParam.size + index) }}</td>
                            <td>{{ item.day }}</td>
                            <td>{{ item.companyName }}</td>
                            <td @click="openImage(item)">{{ item.name }}</td>
                            <td>{{ item.driverType | getDriverType }}</td>
                            <td>{{ item.carNo }}</td>
                            <td>{{ item.startedAt | formatCommute }}</td>
                            <td>{{ item.endedAt | formatCommute }}</td>
                            <td>{{ item.workTimeType | getWorkType }}</td>
                            <td>{{ item.confirmedAt }}</td>
                            <td>{{ item.confirmedName }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <pagination :page-param="pageParam" :list-cnt="listParam.size" @pageCallback="pageCallback" />
        <con-firm :param="confirmParam" @confirmCallback="confirmCallback" />
        <popup-commute-image :detail-item="detailItem" :is-show="detailIsShow" @detailCallback="detailCallback" />
    </div>
</template>
<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import Pagination from '../../layout/Pagination.vue';
import CommuteApi from '../../../api/coco/CommuteApi';
import InfraApi from '../../../api/coco/InfraApi';
import { getToday, toDayFormatAddDay, formatDate } from '../../../utils/date';
import { AUTH_TYPE } from '../../../const';
import { mapGetters } from 'vuex';
import ConFirm from '../Confirm.vue';
import ImageApi from '../../../api/coco/ImageApi';
import PopupCommuteImage from '../PopupCommuteImage.vue';
export default {
    name: 'DashCommute04',
    components: { Pagination, DatePicker, ConFirm, PopupCommuteImage },
    props: {
        tabId: {
            type: Number,
            default: 4
        }
    },
    data: () => {
        return {
            confirmParam: {
                title: '',
                msg: '',
                isShow: false,
                confirm: false
            },
            companyList: [],
            equipList: [],
            pageParam: { content: [] },
            listParam: {
                page: 1,
                size: 10,
                type: 'ETC',
                beginDate: toDayFormatAddDay(-7),
                endDate: getToday(),
                companyId: '',
                driverType: '',
                name: ''
            },
            isShow: false,
            authType: localStorage.getItem(AUTH_TYPE),
            allSelected: false,
            selected: [],
            memberIds: [],
            signFileId: {},
            detailIsShow: false,
            detailItem: null
        };
    },
    computed: { ...mapGetters(['getLang']) },
    watch: {
        tabId: function() {
            if (this.tabId === 4) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {
        const siteId = localStorage.getItem('siteId');
        InfraApi.equipList(siteId).then(res => {
            this.equipList = res.content;
        });
        InfraApi.companyList().then(res => {
            this.companyList = res;
        });
    },
    beforeDestroy() {},
    methods: {
        onClickSearch() {
            this.listParam.page = 1;
            this.list();
        },
        async list() {
            const response = await CommuteApi.bookList(this.listParam);

            const signFileIds = [];

            for (var item of response.content) {
                item.confirmedAt = formatDate(item.confirmedAt);

                if (item.signFileId && !this.signFileId[item.signFileId]) {
                    item.imageUrl = '';
                    signFileIds.push({ fileId: item.signFileId, fileNo: 1 });
                } else {
                    item.imageUrl = this.signFileId[item.signFileId];
                }
                item.isEdit = false;
                item.confirmedAt = formatDate(item.confirmedAt);
            }
            console.log(response);

            //한번에 가져와서 바인딩 하기
            if (signFileIds.length > 0) {
                try {
                    const signResponse = await ImageApi.getBlobList({ ids: signFileIds });
                    signResponse.map(item => {
                        try {
                            const url = 'data:image/png;base64,' + item.blob;
                            const targetRes = response.content.filter(data => data.signFileId === item.id.fileId);
                            targetRes.map(res => {
                                res.imageUrl = url;
                            });
                            this.signFileId[item.id.fileId] = url;
                        } catch (detail) {
                            console.error('not found image');
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
            }
            response.size = this.listParam.size;
            this.pageParam = response;
            console.log(this.pageParam);
        },
        pageCallback(page) {
            this.listParam.page = page;
            this.list();
        },
        contentSubStr(str) {
            if (str) {
                str = str.replace(/<[^>]*>?/g, '');
                if (str.length > 10) {
                    str = str.substr(0, 8) + '...';
                }
            }

            return str;
        },
        displayFault(isFault) {
            return isFault ? '비정상' : '정상';
        },
        excel() {
            const url = '/commute/osm/book-list/excel/forced-worker';
            this.listParam.size = null;
            this.listParam.page = 1;
            CommuteApi.excelDown(url, this.listParam);
            this.listParam.size = 10;
        },
        selectAll() {
            this.memberIds = [];
            this.allSelected = !this.allSelected;
            if (this.allSelected) {
                this.pageParam.content.forEach(element => {
                    this.memberIds.push(element.id);
                });
            }
        },
        select() {
            this.allSelected = false;
            console.log(this.memberIds);
        },
        confirm(memberIds) {
            this.confirmParam.title = '출근일지';
            this.confirmParam.msg = '확정 하시겠습니까?';
            this.confirmParam.isShow = true;
            this.confirmParam.confirm = true;
            this.memberIds = memberIds;
        },
        cancel(memberIds) {
            this.confirmParam.title = '출근일지';
            this.confirmParam.msg = '확정 취소 하시겠습니까?';
            this.confirmParam.isShow = true;
            this.confirmParam.confirm = false;
            this.memberIds = memberIds;
        },
        confirmCallback() {
            if (this.confirmParam.confirm === true) {
                CommuteApi.confirm({ memberIds: this.memberIds }).then(() => {
                    this.list();
                    this.$toast.success('저장 되었습니다.');
                });
            } else if (this.confirmParam.confirm === false) {
                CommuteApi.cancel({ memberIds: this.memberIds }).then(() => {
                    this.list();
                    this.$toast.success('저장 되었습니다.');
                });
            }
        },
        openImage(item) {
            this.detailIsShow = true;
            this.detailItem = item;
            console.log(item);
        },
        detailCallback() {
            this.detailIsShow = false;
            // this.list();
        }
    }
};
</script>
