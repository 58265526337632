<template>
    <div :style="{ display: tabId === 3 ? 'block' : 'none' }" class="dash_admin_box dash_commute_03">
        <div class="dash_admin_box_head">
            <p class="admin_box_title">
                비정상 출근목록
            </p>
        </div>

        <!-- 검색 Wrap -->
        <div class="option_wrap">
            <div class="search_filter">
                <form>
                    <div class="option_date">
                        <date-picker
                            id="startedDate"
                            v-model="listParam.beginDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                        <span>~</span>
                        <date-picker
                            id="endedDate"
                            v-model="listParam.endDate"
                            :lang="getLang"
                            value-type="format"
                            format="YYYY-MM-DD"
                            type="date"
                        />
                    </div>

                    <select v-show="authType === 'ADMIN'" v-model="listParam.companyId" class="search_option">
                        <option value="">
                            선택
                        </option>
                        <option v-for="(code, index) in companyList" :key="index" :value="code.id">
                            {{ code.name }}
                        </option>
                    </select>

                    <select v-show="authType === 'ADMIN'" v-model="listParam.driverType" class="search_option">
                        <option value="">
                            장비종류
                        </option>
                        <option v-for="(equip, index) in equipList" :key="index" :value="equip.type">
                            {{ equip.name }}
                        </option>
                    </select>

                    <div class="option_date w100">
                        <input
                            v-model="listParam.name"
                            type="text"
                            placeholder="성명 검색"
                            @keyup.enter="onClickSearch"
                        />
                    </div>

                    <div class="search_button">
                        <button type="button" @click="onClickSearch">
                            검색
                        </button>
                    </div>
                </form>
            </div>
            <div class="right_area">
                <div class="download_button">
                    <button type="button" @click="excel">
                        엑셀 다운로드
                    </button>
                </div>
            </div>
        </div>
        <!-- 검색 Wrap //-->

        <div class="dash_admin_box_table">
            <table>
                <thead>
                    <tr>
                        <th width="90">
                            순번
                        </th>
                        <th>
                            근무일
                        </th>
                        <th>
                            업체명
                        </th>
                        <th>
                            성명
                        </th>
                        <th>
                            차량종류
                        </th>
                        <th>
                            차량번호
                        </th>
                        <th>
                            출근시간
                        </th>
                        <th>
                            출근위치
                        </th>
                        <th>
                            퇴근시간
                        </th>
                        <th>
                            퇴근위치
                        </th>
                        <th>
                            근무형태
                        </th>
                        <th>
                            확정일자
                        </th>
                        <th>
                            담당자
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="pageParam.total === 0">
                        <td colspan="13">
                            검색 결과가 없습니다.
                        </td>
                    </tr>
                    <template v-if="pageParam.total !== 0">
                        <tr v-for="(item, index) in pageParam.content" :key="index">
                            <td>{{ pageParam.total - ((listParam.page - 1) * listParam.size + index) }}</td>
                            <td>{{ item.day }}</td>
                            <td>{{ item.companyName }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.driverType | getDriverType }}</td>
                            <td>{{ item.carNo }}</td>
                            <td><img :src="imgFaults(item.startedFault)" /> {{ item.startedAt | formatCommute }}</td>
                            <td>
                                <a
                                    v-if="item.startedFault"
                                    style="cursor:pointer"
                                    @click="onClickLocation(item.startedLatitude, item.startedLongitude)"
                                >
                                    <img :src="mapIcon" />
                                </a>
                            </td>
                            <td>
                                <img v-if="item.endedAt" :src="imgFaults(item.endedFault)" />
                                {{ item.endedAt | formatCommute }}
                            </td>
                            <td>
                                <a
                                    v-if="item.endedFault"
                                    style="cursor:pointer"
                                    @click="onClickLocation(item.endedLatitude, item.endedLongitude)"
                                >
                                    <img :src="mapIcon" />
                                </a>
                            </td>
                            <td>{{ item.workTimeType | getWorkType }}</td>
                            <td>{{ item.confirmedAt }}</td>
                            <td>{{ item.confirmedName }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <pagination :page-param="pageParam" :list-cnt="listParam.size" @pageCallback="pageCallback" />
    </div>
</template>
<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import Pagination from '../../layout/Pagination.vue';
import CommuteApi from '../../../api/coco/CommuteApi';
import InfraApi from '../../../api/coco/InfraApi';
import { getToday, toDayFormatAddDay, formatDate } from '../../../utils/date';
import { AUTH_TYPE } from '../../../const';
import { mapGetters } from 'vuex';
export default {
    name: 'DashCommute03',
    components: { Pagination, DatePicker },
    props: {
        tabId: {
            type: Number,
            default: 3
        }
    },
    data: () => {
        return {
            companyList: [],
            pageParam: { content: [] },
            equipList: [],
            listParam: {
                page: 1,
                size: 10,
                isFault: true,
                beginDate: toDayFormatAddDay(-7),
                endDate: getToday(),
                companyId: '',
                driverType: '',
                name: ''
            },
            isShow: false,
            mapIcon: '/img/common/location_map_icon.png',
            authType: localStorage.getItem(AUTH_TYPE)
        };
    },
    computed: { ...mapGetters(['getLang']) },
    watch: {
        tabId: function() {
            if (this.tabId === 3) {
                this.list();
            }
        }
    },
    created() {},
    mounted() {
        const siteId = localStorage.getItem('siteId');
        InfraApi.equipList(siteId).then(res => {
            this.equipList = res.content;
        });
        InfraApi.companyList().then(res => {
            this.companyList = res;
        });
    },
    beforeDestroy() {},
    methods: {
        onClickSearch() {
            this.listParam.page = 1;
            this.list();
        },
        async list() {
            const response = await CommuteApi.bookList(this.listParam);

            for (var item of response.content) {
                item.confirmedAt = formatDate(item.confirmedAt);
            }
            response.size = this.listParam.size;
            this.pageParam = response;
        },
        pageCallback(page) {
            this.listParam.page = page;
            this.list();
        },
        contentSubStr(str) {
            if (str) {
                str = str.replace(/<[^>]*>?/g, '');
                if (str.length > 10) {
                    str = str.substr(0, 8) + '...';
                }
            }

            return str;
        },
        displayFault(isFault) {
            return isFault ? '비정상' : '정상';
        },
        onClickLocation(latitude, longitude) {
            const url = `https://www.google.com/maps/search/${latitude}+${longitude}`;
            window.open(url, 'map');
        },
        imgFaults(item) {
            var url = '/img/common/';
            if (item) url += 'bullet_red_icon.png';
            else url += 'bullet_green_icon.png';

            return url;
            //bullet_green_icon.png
            //bullet_red_icon.png
        },
        excel() {
            const url = '/commute/osm/book-list/excel/outofspace';
            this.listParam.size = null;
            this.listParam.page = 1;
            CommuteApi.excelDown(url, this.listParam);
            this.listParam.size = 10;
        }
    }
};
</script>
