import MutationTypes from '../../MutationTypes';
export default {
    setWorkerList: async ({ commit }, param) => {
        commit(MutationTypes.WORKER_LIST, param);
    },
    setCarList: async ({ commit }, param) => {
        commit(MutationTypes.CAR_LIST, param);
    },
    setMapBounds: async ({ commit }, param) => {
        commit(MutationTypes.MAP_BOUNDS, param);
    },
    setWorkList: async ({ commit }, param) => {
        commit(MutationTypes.WORK_LIST, param);
    }
};
