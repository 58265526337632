<template>
    <div :style="{ display: isShow ? 'block' : 'none' }" class="popup choco_popup" style="display:block;">
        <!-- style="display:block;" -->
        <div class="popup_header">
            <div class="header_title">
                강제 출근자 정보
            </div>
            <button class="close_popup">
                <img src="@static/img/common/popup_close.svg" @click="close" />
            </button>
        </div>
        <div class="popup_content">
            <div class="choco_content">
                <div class="tit_cont_wrap">
                    <p class="title">
                        출근자
                    </p>
                    <p class="text_cont">
                        {{ param.name }}
                    </p>
                </div>
                <div class="tit_cont_wrap">
                    <p class="title">
                        근무일
                    </p>
                    <p class="text_cont">
                        {{ param.day }}
                    </p>
                </div>
                <div class="tit_cont_wrap">
                    <p class="title">
                        출근 시간
                    </p>
                    <p class="text_cont">
                        {{ param.startedAt | formatCommute }}
                    </p>
                </div>
                <div class="tit_cont_wrap">
                    <p class="title">
                        퇴근 시간
                    </p>
                    <p class="text_cont">
                        {{ param.endedAt | formatCommute }}
                    </p>
                </div>

                <div class="job_data">
                    <div :class="!param.signFileId ? 'hide' : ''" class="job_photo">
                        <!-- 사진 없이 텍스트만 있을 땐 hide 붙여요!! -->
                        <div class="swiper">
                            <!-- Additional required wrapper -->
                            <div class="swiper-wrapper">
                                <!-- Slides -->
                                <!-- //TODO img -->
                                <div class="swiper-slide">
                                    <img :src="param.imageUrl" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PopupCommuteImage',
    components: {},
    props: {
        isShow: {
            type: Boolean
        },
        detailItem: {
            type: Object,
            default: null
        }
    },
    data: () => {
        return {
            param: {}
        };
    },
    computed: {},
    watch: {
        detailItem: function() {
            this.param = this.detailItem;
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        close() {
            this.$emit('detailCallback');
        }
    }
};
</script>
