<template>
    <div :style="{ display: menuId === 4 ? 'block' : 'none' }" class="sub_menu_04">
        <div class="sub_menu_inner">
            <h2>현장 공지사항</h2>
            <notice :is-show="isShow" @openNotice="openNotice" />

            <h2>작업지시 현황</h2>
            <div class="option_date">
                <date-picker
                    id="startedDate"
                    v-model="startDate"
                    :lang="getLang"
                    value-type="format"
                    format="YYYY-MM-DD"
                    type="date"
                    :max="endDate"
                />
                <span style="color : white">~</span>
                <date-picker
                    id="endedDate"
                    v-model="endDate"
                    :lang="getLang"
                    value-type="format"
                    format="YYYY-MM-DD"
                    type="date"
                    :min="startDate"
                />
            </div>
            <div class="sub_menu_header">
                <div class="sub_menu_dash dash_wrap">
                    <div class="dash_item dash_item_01" :class="workId === 1 ? 'on' : ''" @click="workId = 1">
                        <div class="item_num" @click="workId = 1">{{ summaryCount.totalCount }}</div>
                        <div class="item_title">전체 건수</div>
                    </div>
                    <div class="dash_item dash_item_02" :class="workId === 2 ? 'on' : ''" @click="workId = 2">
                        <div class="item_num" @click="workId = 2">{{ summaryCount.processCount }}</div>
                        <div class="item_title">처리 건수</div>
                    </div>
                    <div class="dash_item dash_item_03" :class="workId === 3 ? 'on' : ''" @click="workId = 3">
                        <div class="item_num">{{ summaryCount.restCount }}</div>
                        <div class="item_title">미처리 건수</div>
                    </div>
                </div>
            </div>
            <h2>실시간 작업지시</h2>
            <work-log-content
                :end-date="endDate"
                :start-date="startDate"
                :work-id="workId"
                :is-show="isShow"
                @openPopup="openPopup"
            />
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import { getToday, toDayFormatAddDay } from '../../../utils/date';
import Notice from './Menu04/Notice.vue';
import WorkLogContent from './Menu04/WorkLogContent.vue';
import WorkApi from '../../../api/coco/WorkApi';
import { DEV_LEVEL } from '../../../const';
export default {
    name: 'Menu04',
    components: { Notice, WorkLogContent, DatePicker },
    props: {
        menuId: {
            type: Number,
            required: true
        }
    },
    data: () => {
        return {
            workId: 1,
            isShow: false,
            summaryCount: {
                totalCount: 0,
                processCount: 0,
                restCount: 0
            },
            detailItem: null,
            detailIsShow: false,
            devLevel: localStorage.getItem(DEV_LEVEL) ? localStorage.getItem(DEV_LEVEL) : 0,

            startDate: toDayFormatAddDay(-30),
            endDate: getToday()
        };
    },
    computed: {
        ...mapGetters(['getLang']),
        ...mapState({
            workList: state => state.common.workList
        })
    },
    watch: {
        workList: function() {
            this.workId = 1;
            this.startDate = toDayFormatAddDay(-30);
            this.endDate = getToday();
        },
        menuId: function() {
            if (this.menuId === 4) {
                this.isShow = true;
            } else {
                this.isShow = false;
            }
        },
        startDate: function() {
            this.statusCount();
        },
        endDate: function() {
            this.statusCount();
        }
    },
    created() {},
    mounted() {
        this.statusCount();
        if (this.devLevel < 2) setInterval(this.statusCount, 1000 * 10); //재조회 1분
    },
    beforeDestroy() {},
    methods: {
        statusCount() {
            if (this.startDate === null) {
                this.startDate = toDayFormatAddDay(-30);
            }
            if (this.endDate === null) {
                this.endDate = getToday();
            }
            const param = {
                startDate: this.startDate,
                endDate: this.endDate
            };
            WorkApi.status(param).then(res => {
                this.summaryCount = res.content;
                this.summaryCount.restCount = this.summaryCount.totalCount - this.summaryCount.processCount;
            });
        },
        openPopup(item) {
            this.$emit('openWorkView', item);
        },
        openNotice(item) {
            this.$emit('openNotice', item);
        }
    }
};
</script>
<style scoped>
.mx-datepicker {
    width: 150px !important;
    padding: 17px !important;
}
</style>
