<template>
    <div
        style="display: block;"
        :style="{ display: currentPopup === POPUP_NAME.CALENDAR ? 'block' : 'none' }"
        class="calander_popup"
    >
        <div class="calander_header">
            <p class="header_title">{{ siteName }} 업무 캘린더</p>
            <p class="header_today">{{ year }}. {{ month }}</p>
            <nav class="navbar">
                <button class="button is-rounded prev" @click="movePrevMonth">
                    <img alt="prev" src="@static/img/common/arrow_prev.svg" />
                </button>
                <span class="navbar--today">{{ year }}. {{ month }}</span>
                <button class="button is-rounded next" @click="moveNextMonth">
                    <img alt="prev" src="@static/img/common/arrow_fowd.svg" />
                </button>
            </nav>
            <div class="admin_head_button">
                <button v-show="!isManager" type="button" class="type_blue calendar" @click="openPopup">
                    일정 등록
                </button>
            </div>
        </div>
        <div class="calander_content">
            <div id="app" />
        </div>

        <popup-modify-site :detail-item="detailItem" :is-show="detailIsShow" @detailCallback="detailCallback" />
    </div>
</template>
<script>
import Calendar from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { POPUP_NAME, SITE_NAME } from '../../const';
import { dayFormat } from '../../utils/date';
import DailyLogApi from '../../api/osm/DailyLogApi';
import WeatherApi from '../../api/coco/WeatherApi';
import PopupModifySite from './PopupModifySite.vue';
export default {
    name: 'PopupCalendar',
    components: { PopupModifySite },
    props: {
        currentPopup: {
            type: String,
            default: ''
        },
        isManager: {
            type: Boolean,
            required: false
        }
    },
    data: () => {
        return {
            POPUP_NAME: POPUP_NAME,
            content: [],
            listParam: { page: 1, size: 10 },
            pageParam: [],
            weather: [],
            calendar: null,
            calendarId: null,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            detailItem: null,
            detailIsShow: false,
            siteName: localStorage.getItem(SITE_NAME)
        };
    },
    computed: {},
    created() {
        this.weatherList();
    },
    mounted() {
        this.list();
    },
    beforeDestroy() {},
    methods: {
        detailCallback() {
            this.detailIsShow = false;
            this.list();
        },
        list() {
            this.setContent();
            if (this.calendar) {
                this.calendar.render();
            } else {
                var MOCK_CALENDARS = [
                    {
                        id: '1',
                        name: 'My Calendar',
                        color: '#ffffff',
                        borderColor: '#41321c',
                        backgroundColor: '#886226',
                        dragBackgroundColor: '#886226'
                    },
                    {
                        id: '2',
                        name: 'Work',
                        color: '#ffffff',
                        borderColor: '#0c6356',
                        backgroundColor: '#0f3230',
                        dragBackgroundColor: '#0f3230'
                    },
                    {
                        id: '3',
                        name: 'Family',
                        color: '#ffffff',
                        borderColor: '#53338b',
                        backgroundColor: '#2c1f45',
                        dragBackgroundColor: '#2c1f45'
                    },
                    {
                        id: '4',
                        name: 'Friends',
                        color: '#ffffff',
                        borderColor: '##09588b',
                        backgroundColor: '#0e2e45',
                        dragBackgroundColor: '#0e2e45'
                    },
                    {
                        id: '5',
                        name: 'Travel',
                        color: '#ffffff',
                        borderColor: '#62740c',
                        backgroundColor: '#323912',
                        dragBackgroundColor: '#323912'
                    }
                ];
                const that = this;
                this.calendar = new Calendar('#app', {
                    usageStatistics: false,
                    defaultView: 'month',
                    calendars: MOCK_CALENDARS,
                    useCreationPopup: false,
                    useDetailPopup: false,
                    isReadOnly: true,
                    month: {
                        startDayOfWeek: 1,
                        isAlways6Weeks: false,
                        visibleEventCount: 2
                    },
                    template: {
                        monthGridHeader(model) {
                            let imgPath = '';
                            //다른 달의 날짜는 나타내지 않음
                            let date = new Date(model.date).getDate();
                            if (model.isOtherMonth === true) {
                                date = `<span style="color: grey;">${date}</span>`;
                            }

                            if (that.weather !== undefined) {
                                that.weather.forEach(element => {
                                    if (element.baseDate === model.date) {
                                        if (element.displayType === '맑음') {
                                            imgPath = '<img alt="prev" src="/img/common/weather/weather_01.svg" />';
                                        } else if (element.displayType === '흐림') {
                                            imgPath = '<img alt="prev" src="/img/common/weather/weather_03.svg" />';
                                        } else if (element.displayType === '눈') {
                                            imgPath = '<img alt="prev" src="/img/common/weather/weather_10.svg" />';
                                        } else if (element.displayType === '비') {
                                            imgPath = '<img alt="prev" src="/img/common/weather/weather_06.svg" />';
                                        }
                                    }
                                });
                            }
                            // return `<span id='cell-${dayFormat(new Date(model.date))}'>${date}  ${imgPath}  </span>`;
                            if (model.isToday === true) {
                                return `<span id='cell-${dayFormat(
                                    new Date(model.date)
                                )}-today'>${date}  ${imgPath}  </span>`;
                            } else {
                                return `<span id='cell-${dayFormat(
                                    new Date(model.date)
                                )}'>${date}  ${imgPath}  </span>`;
                            }
                        }
                    }
                });
                setTimeout(() => {
                    console.log($(`#cell-${dayFormat(new Date())}`));
                    $(`#cell-${dayFormat(new Date())}-today`)
                        .parents('.toastui-calendar-daygrid-cell')
                        .addClass('today');
                }, 500);

                this.calendar.on('clickEvent', ({ event }) => {
                    this.popupModify(event.id);
                });
            }
        },
        weatherList() {
            WeatherApi.list({
                startDate: dayFormat(new Date(this.year, this.month - 1, 1)).replaceAll('-', ''),
                endDate: dayFormat(new Date(this.year, this.month, 0)).replaceAll('-', '')
            }).then(result => {
                this.weather = result;
                this.testItem = result;
            });
        },
        setContent() {
            if (this.calendar) this.calendar.clear();
            this.content = [];
            DailyLogApi.list(this.listParam).then(result => {
                result.size = this.listParam.size;
                this.pageParam = result;
                this.pageParam.content.forEach(element => {
                    if (element.labelClass === 'orange') {
                        this.calendarId = '1';
                    } else if (element.labelClass === 'green') {
                        this.calendarId = '2';
                    } else if (element.labelClass === 'purple') {
                        this.calendarId = '3';
                    } else if (element.labelClass === 'blue') {
                        this.calendarId = '4';
                    } else if (element.labelClass === 'yellow') {
                        this.calendarId = '5';
                    }
                    console.log(this.calendarId);
                    this.content.push({
                        id: element.id,
                        calendarId: this.calendarId,
                        title: element.title,
                        start: element.startDate,
                        end: element.endDate,
                        category: 'allday',
                        isReadOnly: true
                    });
                });
                this.calendar.createEvents(this.content);
            });
        },
        movePrevMonth() {
            this.month = this.month - 1;
            this.calendar.move(-1);
            if (this.month === 0) {
                this.year = this.year - 1;
                this.month = 12;
            }
            this.weatherList();
            this.list();
            $(`.toastui-calendar-daygrid-cell.today`).removeClass('today');

            if ($(`#cell-${dayFormat(new Date())}-today`).length === 1) {
                $(`#cell-${dayFormat(new Date())}-today`)
                    .parents('.toastui-calendar-daygrid-cell')
                    .addClass('today');
            }
        },
        moveNextMonth() {
            this.month = this.month + 1;
            this.calendar.move(+1);
            if (this.month > 12) {
                this.year = this.year + 1;
                this.month = 1;
            }
            this.weatherList();
            this.list();
            $(`.toastui-calendar-daygrid-cell.today`).removeClass('today');

            if ($(`#cell-${dayFormat(new Date())}-today`).length === 1) {
                $(`#cell-${dayFormat(new Date())}-today`)
                    .parents('.toastui-calendar-daygrid-cell')
                    .addClass('today');
            }
        },
        popupModify(dailyLogId) {
            const targetItem = this.pageParam.content.find(item => item.id === dailyLogId);

            this.detailIsShow = true;
            this.detailItem = targetItem;
        },
        openPopup() {
            this.detailIsShow = true;
            const detailItem = { labelClass: 'blue', startDate: '', endDate: '', content: '', title: '' };
            this.detailItem = detailItem;
        }
    }
};
</script>
