<template>
    <div :style="{ display: isShow ? 'block' : 'none' }" class="popup modify_site">
        <div class="popup_header">
            <div class="header_title">현장 일정</div>
            <button @click="close" class="close_popup">
                <img src="@static/img/common/popup_close.svg" alt="팝업 닫기" />
            </button>
        </div>
        <div class="popup_content">
            <div class="form_wrap">
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">라벨 컬러 선택</p>
                        <p class="input">
                            <label class="custom_radio color_label color_blue">
                                <input v-model="param.labelClass" type="radio" name="modi_color_01" value="blue" />
                                <span>파랑</span>
                            </label>
                            <label class="custom_radio color_label color_yellow">
                                <input v-model="param.labelClass" type="radio" name="modi_color_01" value="yellow" />
                                <span> 노랑</span>
                            </label>
                            <label class="custom_radio color_label color_purple">
                                <input v-model="param.labelClass" type="radio" name="modi_color_01" value="purple" />
                                <span>보라</span>
                            </label>
                            <label class="custom_radio color_label color_green">
                                <input v-model="param.labelClass" type="radio" name="modi_color_01" value="green" />
                                <span>초록</span>
                            </label>
                            <label class="custom_radio color_label color_orange">
                                <input v-model="param.labelClass" type="radio" name="modi_color_01" value="orange" />
                                <span>주황</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">시작일 / 종료일</p>
                        <p class="input">
                            <date-picker
                                v-model="day"
                                :lang="getLang"
                                value-type="format"
                                format="YYYY-MM-DD"
                                type="date"
                                range
                            />
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">제목</p>
                        <p class="input">
                            <input v-model="param.title" type="text" placeholder="30자 이내로 작성하세요." />
                        </p>
                    </div>
                </div>
                <div class="form_item">
                    <div class="form_write">
                        <p class="title">일정 내용</p>
                        <p class="input">
                            <textarea v-model="param.content" placeholder="내용을 입력하세요" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup_footer">
            <div class="foot_btns">
                <button type="button" class="cancel" @click="close">취소</button>
                <button type="button" class="comfirm" @click="save">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import DailyLogApi from '../../api/osm/DailyLogApi';
import { mapGetters } from 'vuex';

export default {
    name: 'PopupModifySite',
    components: { DatePicker },
    props: {
        isShow: {
            type: Boolean
        },
        detailItem: {
            type: Object,
            default: null
        }
    },
    data: () => {
        return {
            param: {
                labelClass: 'blue',
                startDate: '',
                endDate: '',
                content: '',
                title: ''
            },
            day: []
        };
    },
    computed: { ...mapGetters(['getLang']) },
    watch: {
        detailItem: function() {
            this.param = this.detailItem;
            this.day.push(this.param.startDate);
            this.day.push(this.param.endDate);
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        close() {
            this.day = [];
            this.$emit('detailCallback');
        },
        save() {
            this.param.startDate = this.day[0];
            this.param.endDate = this.day[1];
            if (!this.param.startDate || !this.param.endDate) {
                this.$toast.warning('기간을 입력 해 주세요.');
                return;
            }
            if (this.param.id) {
                DailyLogApi.update(this.param.id, this.param).then(() => {
                    this.$toast.success('저장 되었습니다.');
                    this.$emit('detailCallback');
                });
            } else {
                DailyLogApi.save(this.param).then(() => {
                    this.$toast.success('저장 되었습니다.');
                    this.$emit('detailCallback');
                });
            }
            this.param.labelClass = 'blue';
            this.param.startDate = '';
            this.param.endDate = '';
            this.param.content = '';
            this.param.title = '';
            this.day = [];
        }
    }
};
</script>
